import parse_pdf from "workflow/actions/parse_pdf"

async function* drop_first(gen) {
    await gen.next()
    yield* gen
}

// Takes a single pdf and returns a list of

// {
//     source: 'http://localhost/file.pdf',
//     page: 1,
//     output: 'blob://...',
//     trim_box: {x: 0, y: 0, width: 7000, height: 9000},
// }

export default (...args) => drop_first(parse_pdf(...args))
