import toggle_class from "js/dom/toggle_class"
import scroll_to from "js/utils/scroll_to"
import virtual from "workflow/vue/3/virtual"

export default virtual({
    data: () => {
        return {
            open: false,
            open_for: null,
            tag: null
        }
    },
    methods: {
        delegate_open_for: function (open, open_for) {
            if (open || (!open && this.open_for == open_for)) {
                this.open = open
                this.open_for = open_for
            }
        }
    },
    watch: {
        open: function (v) {
            console.log("open is changed", v)
            toggle_class(document.body, "search-body", v)
            scroll_to()
        }
    }
})
