import get_django_url from "js/utils/get_django_url"
import filter from "rfuncs/functions/filter"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import scan from "rfuncs/functions/scan"
import json from "workflow/utils/json"
import url_read from "workflow/utils/url_read_tracked"
import { url_query_encode } from "workflow/utils/urlutilities"

async function make_upload_task(signing_request, file, index) {
    const response = await signing_request

    if (response && response.success) {
        const signing = response.file[index]

        const barray = await url_read(file.url, {
            with_credentials: false,
            response_type: "arraybuffer"
        }).then(xhttp => new Uint8Array(xhttp.response))

        const data = new FormData()

        scan((v, k) => data.append(k, v), signing.fields)

        data.append("file", new Blob([barray], { type: file.type }), file.name)

        const xhttp = await url_read(signing.url, {
            with_credentials: false,
            headers: {},
            data: data
        })

        if (xhttp.status < 299) {
            return {
                type: signing.info.content_type,
                remote_id: signing.info.location
            }
        }

        throw "Invalid response from server: " + xhttp.responseText
    }

    return {}
}

export default files => {
    const non_remote_files = filter(f => !f.remote_name, files)

    if (length(non_remote_files) > 0) {
        const signing_request = get_django_url("upload_s3").then(sign_url =>
            url_read(
                sign_url +
                    "?" +
                    url_query_encode({
                        type: map(f => f.type, non_remote_files),
                        name: map(f => f.name, non_remote_files)
                    }),
                {
                    with_credentials: true,
                    success: xhttp => json.loads(xhttp.responseText)
                }
            )
        )

        scan(
            (f, index) =>
                f.wait(f => make_upload_task(signing_request, f, index)),
            non_remote_files
        )
    }

    return files
}
