import is_string from "rfuncs/functions/is_string"
import object_map from "rfuncs/functions/object_map"

export default (object, keys) =>
    object_map(
        n => n,
        (n, k) => {
            const target = is_string(k) ? k : n
            return {
                get: () => object[target],
                set: v => (object[target] = v)
            }
        },
        keys
    )
