import selector from "js/dom/selector"
import filter from "rfuncs/functions/filter"
import scan from "rfuncs/functions/scan"
import { url_encode } from "workflow/utils/urlutilities"

export default form_id => {
    // Setup our serialized data
    const serialized = []
    const add = (n, v) => serialized.push(url_encode(n) + "=" + url_encode(v))

    scan(form => {
        scan(
            field => {
                // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
                if (
                    !field.name ||
                    field.disabled ||
                    field.type === "file" ||
                    field.type === "reset" ||
                    field.type === "submit" ||
                    field.type === "button"
                ) {
                    // eslint-disable-line no-empty
                }

                // If a multi-select, get all selections
                else if (field.type === "select-multiple") {
                    scan(
                        opt => add(field.name, opt.value),
                        filter(opt => opt.selected, field.options)
                    )
                }

                // Convert field data to a query string
                else if (
                    (field.type !== "checkbox" && field.type !== "radio") ||
                    field.checked
                ) {
                    add(field.name, field.value)
                }
            },
            [...form.elements] // in safari scan is doubling the elements, because keys(...) is returning a longer list
        )
    }, selector(form_id))

    return serialized.join("&")
}
