/**
 * register plugins
 */

export default (element, event, detail = null) => {
    element.dispatchEvent(
        new (detail ? CustomEvent : Event)(event, {
            bubbles: true,
            cancelable: true,
            detail: detail
        })
    )
}
