import get_header from "js/utils/get_header"

export default r => {
    // supports both fetch and xhttp

    const h = get_header(r, "content-disposition")

    try {
        return h.split("filename=")[1].split(";")[0]
    } catch (e) {
        return ""
    }
}
