import toggle_class from "js/dom/toggle_class"
import user from "js/state/user"
import persistence from "js/utils/persistence"
import scan from "rfuncs/functions/scan"
import virtual from "workflow/vue/3/virtual"

const store = persistence.make_serializer("cookielaw")

export default virtual({
    data: () => {
        return {
            IS_SUBMITTED: false,
            LAST_FOCUS: null,
            AJAX_REQUEST_CACHE: {},
            AJAX_COUNTER: 0,

            location: document.location.href,
            last_location: document.location.href,
            request_id: null,
            event_handlers: [],
            settings: {},
            translations: {},
            cookielaw: store.get(),
            user: user // this is done because settings_set is relying on it
        }
    },
    methods: {
        track_event(...args) {
            scan(func => func(...args), this.event_handlers)
        }
    },
    watch: {
        cookielaw(value) {
            store.set(value)
            toggle_class(
                document.body,
                ["body-cookielaw", "body-cookielaw-big"],
                !value
            )
        }
    }
})
