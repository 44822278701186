import FILEMETA_SPLIT_CHARACTER from "constants/FILEMETA_SPLIT_CHARACTER"
import append_slash from "js/files/naming/append_slash"
import filter from "rfuncs/functions/filter"
import merge from "rfuncs/functions/merge"

export default (...filedata) => {
    const merged = merge(...filedata)
    const basename =
        filter(
            s => (s ? s.length > 0 : false),
            [merged.basename, merged.uuid]
        ).join(FILEMETA_SPLIT_CHARACTER) + (merged.ext ? `.${merged.ext}` : "")

    if (merged.folder) {
        return append_slash(merged.folder) + basename
    }

    return basename
}
