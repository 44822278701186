<template>
    <div class="d-flex align-items-center border-0 p-0">
        <template v-if="operation_perc > 0">
            {{ parseInt(operation_perc * 100) }}%
        </template>
        <span class="mx-1 spinner-border spinner-border-sm" />
    </div>
</template>

<script>
export default {
    props: ["operation_perc"]
}
</script>
