<template>
    <div :class="show_second ? 'input-group' : ''">
        <input
            v-if="datalist"
            ref="datalist"
            class="form-control"
            :list="uuid"
            :placeholder="drop_emoji(placeholder)"
            :title="placeholder"
            type="text"
            @change.stop.prevent="datalist_select"
        />

        <datalist v-if="datalist" :id="uuid">
            <template v-for="(value, pk) in options" :key="pk">
                <template v-for="(value, label) in value" :key="label">
                    <option readonly :value="label"></option>
                    <option
                        v-for="(value, pk) in value"
                        :key="pk"
                        :selected="pk == current"
                        :value="value"
                    ></option>
                </template>
            </template>
        </datalist>
        <select
            v-model="selection"
            class="form-select prevent-submit-onchange"
            :class="{
                'is-invalid': is_invalid,
                'flex-grow-0': show_second && size_1
            }"
            :disabled="disabled"
            :style="{
                width: show_second ? size_1 : null
            }"
            :title="label"
        >
            <option v-if="is_invalid" :value="selection">{{ value }}</option>
            <template v-for="(inner, label) in options" :key="label">
                <optgroup v-if="label" :label="label">
                    <option
                        v-for="(_, opt) in inner"
                        :key="make_key(label, opt)"
                        :value="make_key(label, opt)"
                    >
                        {{ opt }}
                    </option>
                </optgroup>
                <template v-else>
                    <option
                        v-for="(_, opt) in inner"
                        :key="make_key(label, opt)"
                        :value="make_key(label, opt)"
                    >
                        {{ opt }}
                    </option>
                </template>
            </template>
        </select>
        <input ref="hidden" v-model="current" :name="name" type="hidden" />
        <select
            v-if="show_second"
            ref="second"
            v-model="current"
            class="form-select prevent-submit-onchange"
            :class="{
                'flex-grow-0': size_2
            }"
            :disabled="disabled || length(options_visible) <= 1"
            :style="{
                width: size_2
            }"
            :title="placeholder"
        >
            <optgroup :label="label">
                <option
                    v-for="(title, pk) in options_visible"
                    :key="pk"
                    :value="pk"
                >
                    {{ title }}
                </option>
            </optgroup>
        </select>

        <slot name="buttons"></slot>
    </div>
</template>
<script>
import fire_event from "js/dom/fire_event"
import apply from "rfuncs/functions/apply"
import contains from "rfuncs/functions/contains"
import first from "rfuncs/functions/first"
import keys from "rfuncs/functions/keys"
import last from "rfuncs/functions/last"
import length from "rfuncs/functions/length"
import uuid from "workflow/utils/uuid"

const divider = "///"

export default {
    data() {
        return {
            current: this.value,
            uuid: uuid()
        }
    },
    props: [
        "name",
        "value",
        "options",
        "size_1",
        "size_2",
        "disabled",
        "datalist"
    ],

    watch: {
        current() {
            this.$refs.hidden.value = this.current
            fire_event(this.$refs.hidden, "change")
            this.$emit("selection", this.current)
        }
    },

    methods: {
        length,
        first,

        drop_emoji(text) {
            // this is an hack for the country controller, I'm removing flag emojis from the datalist
            // right now the datalist feature is only used by countries.

            if (text) {
                return text.replace(/[\u{1F1E6}-\u{1F1FF}]{2}/gu, "").trim()
            }
        },

        find_value(v) {
            for (const l in this.options) {
                for (const g in this.options[l]) {
                    if (contains(this.options[l][g], v)) {
                        return this.make_key(l, g)
                    }
                }
            }
            return this.make_key("", "")
        },
        make_key: (label, selection) => label + divider + selection,

        options_for_selection(selection) {
            return apply(
                (l, g) => (this.options[l] ? this.options[l][g] : {}),
                selection.split(divider)
            )
        },
        datalist_select() {
            const value = this.$refs.datalist.value

            this.$refs.datalist.value = ""
            this.$refs.datalist.blur()

            for (const l in this.options) {
                for (const g in this.options[l]) {
                    for (const pk in this.options[l][g]) {
                        if (this.options[l][g][pk] == value || pk == value) {
                            this.current = pk
                            return
                        }
                    }
                }
            }
        }
    },
    computed: {
        show_second() {
            return (
                length(this.options_visible) >= 1 && first(this.options_visible)
            )
        },
        label() {
            return last(this.selection.split(divider))
        },
        options_visible() {
            return this.options_for_selection(this.selection)
        },
        is_invalid() {
            return length(this.options_visible) == 0
        },

        selection: {
            get() {
                return this.find_value(this.current)
            },
            set(value) {
                this.current = first(keys(this.options_for_selection(value)))
            }
        },
        placeholder() {
            return this.options_visible[this.current]
        }
    }
}
</script>
