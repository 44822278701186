import merge from "rfuncs/functions/merge"
import tracking from "workflow/state/tracking"
import url_read from "workflow/utils/url_read"

export default (url, data, timeout = 10) =>
    tracking.track_promise(
        emit =>
            url_read(
                url,
                merge(data, {
                    progress: emit,
                    upload_progress: emit
                })
            ),
        timeout
    )
