import ready from "js/api/ready"
import registry from "js/api/registry"
import append from "js/dom/append"
import apply from "rfuncs/functions/apply"
import scan from "rfuncs/functions/scan"

function make_entry(url) {
    const obj = { script: document.createElement("script"), registry: [] }
    obj.script.onload = () => {
        obj.push = apply
        scan(apply, obj.registry)
    }
    obj.script.src = url
    obj.push = f => obj.registry.push(f)

    append("body", obj.script)

    return f => obj.push(f)
}

export default (name, url, func) => {
    if (!registry[name]) {
        registry[name] = make_entry(url)
    }
    if (func) ready(name, func)
}
