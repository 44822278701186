import element from "js/dom/element"
import wait_files from "js/files/actions/wait_files"
import new_zip_from_files from "js/files/make/new_zip_from_files"
import first from "rfuncs/functions/first"
import length from "rfuncs/functions/length"
import to_array from "rfuncs/functions/to_array"

export default async (files, force_download = true) => {
    const target = first(
        await wait_files(
            length(files) > 1
                ? [new_zip_from_files(to_array(files))]
                : to_array(files)
        )
    )

    if (target) {
        // make and click a temporary link to download the Blob
        const link = element("a")
        link.href = target.url
        if (force_download) {
            link.download = target.name
        }
        link.target = "_blank"
        link.click()
        link.remove()
    }
}
