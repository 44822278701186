import new_file_from_bytes from "js/files/make/new_file_from_bytes"
import map from "rfuncs/functions/map"

export default files =>
    map(
        file =>
            new_file_from_bytes({
                bytes: file,
                name: file.name,
                type: file.type
            }),
        files
    )
