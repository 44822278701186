import identity from "rfuncs/functions/identity"
import scan from "rfuncs/functions/scan"
import { DOWNLOAD, UPLOAD } from "translations/enum/trackers"
import uuid from "workflow/utils/uuid"

function get_headers(response) {
    var headers = {}

    scan(
        line => {
            var parts = line.split(": ")
            var header = parts.shift()
            var value = parts.join(": ")
            headers[header] = value
        },
        response
            .getAllResponseHeaders()
            .trim()
            .split(/[\r\n]+/)
    )
    return headers
}

export default function (
    url,
    {
        success = null,
        failure = null,
        progress = null,
        upload_progress = null,
        data = null,
        response_type = null,
        with_credentials = true,
        headers = null
    }
) {
    if (!url) {
        throw "URL IS REQUIRED"
    }

    const id = uuid()

    let resolved = false

    const on_success = success || identity
    const on_failure = failure || identity

    return new Promise((resolve, reject) => {
        var xhttp = new XMLHttpRequest()
        if (response_type) {
            xhttp.responseType = response_type
        }
        xhttp.onreadystatechange = function () {
            if (xhttp.readyState == xhttp.HEADERS_RECEIVED && progress) {
                // Get the raw header string
                const headers = get_headers(xhttp)
                if (headers["content-length"]) {
                    progress(DOWNLOAD, id, {
                        done: 0,
                        total: parseInt(headers["content-length"])
                    })
                }
            }

            if (xhttp.readyState == xhttp.DONE) {
                resolved = true
                if (xhttp.status >= 200 && xhttp.status < 400) {
                    resolve(on_success(xhttp))
                } else {
                    reject(on_failure(xhttp))
                }
            }
        }

        scan(
            ([source, func, name]) => {
                if (source && func)
                    scan(
                        k => {
                            source.addEventListener(k, evt =>
                                resolved
                                    ? null
                                    : func(name, id, {
                                          done: evt.loaded,
                                          total: evt.total
                                      })
                            )
                        },
                        ["loadstart", "load", "progress", "loadend"]
                    )
            },
            [
                [xhttp, progress, DOWNLOAD],
                [xhttp.upload, upload_progress, UPLOAD]
            ]
        )

        xhttp.onerror = reject
        xhttp.open(data ? "POST" : "GET", url, true)

        if (!headers) {
            xhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest")
        }

        xhttp.withCredentials = with_credentials
        if (data && !headers)
            xhttp.setRequestHeader(
                "Content-Type",
                "application/x-www-form-urlencoded"
            )

        scan((v, k) => xhttp.setRequestHeader(k, v), headers || {})

        xhttp.send(data)
    })
}
