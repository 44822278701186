import SESSION_PARAMETERS from "constants/SESSION_PARAMETERS"
import object_map from "rfuncs/functions/object_map"
import base64safe from "workflow/utils/base64safe"
import json from "workflow/utils/json"

export default function user_get(string) {
    if (string) {
        let data = json.loads(base64safe.loads(string))
        return object_map(
            (v, k) => k,
            v => data[v],
            SESSION_PARAMETERS
        )
    } else {
        return {
            pk: null,
            is_staff: false,
            email: null,
            name: null,
            cart_counter: 0,
            has_discount: false,
            signup_time: 0
        }
    }
}
