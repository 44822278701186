import on from "js/dom/on"
import is_string from "rfuncs/functions/is_string"
import merge from "rfuncs/functions/merge"
import partial from "rfuncs/functions/partial"
import { GENERIC_ERROR } from "translations/enum/errors"
import { get_translations } from "workflow/utils/make_translations"
import sleep from "workflow/utils/sleep"
import uuid from "workflow/utils/uuid"
import delete_property from "workflow/vue/3/delete_property"
import set_property from "workflow/vue/3/set_property"
import virtual from "workflow/vue/3/virtual"

export default virtual({
    data: () => {
        return { messages: {} }
    },
    methods: {
        add: function (m, task) {
            const counter = task ? task.counter : null
            const res = is_string(m)
                ? { content: m, counter: counter }
                : merge(m, { counter })
            res.pk = res.pk || uuid()
            set_property(this.messages, res.pk, res)

            const remove = partial(this.remove, res.pk)

            if (task) {
                task.then(remove)
            } else {
                sleep(5000).then(remove)
            }
        },
        remove: function (pk) {
            return delete_property(this.messages, pk)
        },
        clear: function () {
            this.messages = {}
        }
    },
    mounted() {
        on("tracked_error", null, (el, { detail: error }) =>
            get_translations("errors").then(lib =>
                this.add({
                    content: lib[error] || lib[GENERIC_ERROR],
                    class: "danger"
                })
            )
        )
    }
})
