<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    name: "NaivePortal",
    props: {
        to: {
            type: String,
            required: true
        }
    },
    mounted() {
        const target = document.getElementById(this.to)
        if (target) {
            target.append(this.$el)
        }
    },
    beforeUnmount() {
        const target = this.$el
        target.parentNode.removeChild(target)
    }
}
</script>
