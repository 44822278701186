<template>
    <div
        class="text-collapsable"
        :class="{ 'text-collapsed': is_collapsed && needs_collapse }"
    >
        <div
            ref="text"
            class="text-content"
            :style="{ 'max-height': is_collapsed ? max_height + 'px' : null }"
            v-html="content"
        />
        <div
            v-if="needs_collapse"
            :class="link ? 'text-start pt-0' : 'text-center pt-3'"
        >
            <a
                :class="link ? 'text-muted small' : 'btn btn-outline-light'"
                href="#"
                @click.prevent.stop="() => (collapsed = !collapsed)"
            >
                {{
                    collapsed
                        ? translations.actions.SHOW_ALL
                        : translations.actions.HIDE
                }}
                &rarr;
            </a>
        </div>
    </div>
</template>

<script>
import state from "js/state/main"
import make_translations from "workflow/utils/make_translations"

export default {
    props: ["content", "collapsed", "height", "link"],
    data() {
        return {
            needs_collapse: false,
            max_height: this.height || 300
        }
    },
    watch: {
        collapsed(v) {
            if (!v) {
                state.track_event("button_expand", "expand")
            }
        }
    },
    computed: {
        translations: () => make_translations("actions"),
        is_collapsed() {
            return this.collapsed && this.needs_collapse
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.needs_collapse = this.$refs.text.clientHeight > this.max_height
        })
    }
}
</script>
