import register_dom_update from "js/navigation/register_dom_update"
import state from "js/state/main"

function make_tracking_function(name) {
    return (e, event) => {
        if (!event.detail.initial) {
            state.track_event(name)
        }
    }
}

register_dom_update({
    login: make_tracking_function("login"),
    logout: make_tracking_function("logout"),
    signup: make_tracking_function("sign_up")
})
