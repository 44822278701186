<template>
    <datalist v-if="objects" :id="id">
        <option v-for="[name, size] in sizes" :value="size" v-html="name" />
    </datalist>
</template>

<script>
import autocomplete from "js/constants/autocomplete"
import all from "rfuncs/functions/all"
import contains from "rfuncs/functions/contains"
import filter from "rfuncs/functions/filter"
import last from "rfuncs/functions/last"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import zip from "rfuncs/functions/zip"

export default {
    components: {},
    props: ["id", "autocomplete", "units", "min", "max", "target"],

    computed: {
        objects() {
            const response = autocomplete[this.autocomplete]
            return response ? response.objects : null
        },

        sizes() {
            if (this.objects) {
                const unit_filter = length(this.units)
                    ? formatted =>
                          contains(this.units, last(formatted.split(" ")))
                    : formatted => true

                const is_contained = measures =>
                    all(
                        zip(this.min, measures, this.max),
                        ([min, current, max]) =>
                            current >= min && current <= max
                    )

                return map(
                    ([name, formatted, ...sizes]) => [name, formatted],
                    filter(
                        ([name, formatted, ...sizes]) =>
                            unit_filter(formatted) && is_contained(sizes),
                        this.objects
                    )
                )
            }
        }
    }
}
</script>
