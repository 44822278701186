<template>
    <double
        :datalist="datalist"
        :name="name"
        :options="options"
        :size_1="size_1"
        :size_2="size_2"
        :value="value"
    >
        <template #buttons>
            <slot name="buttons"></slot>
        </template>
    </double>
</template>

<script>
import double from "js/comp/double"
import autocomplete from "js/constants/autocomplete"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"

export default {
    components: { double },
    props: [
        "name",
        "value",
        "autocomplete",
        "extra",
        "size_1",
        "size_2",
        "datalist"
    ],
    computed: {
        options() {
            const content = autocomplete[this.autocomplete]

            if (content) {
                return merge({}, this.extra || {}, {
                    [content.title || ""]: object_map(
                        c => c.name,
                        c => c.objects,
                        content.objects
                    )
                })
            }
            return this.extra || {}
        }
    }
}
</script>
