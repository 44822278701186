/**
 * register plugins
 */
import is_string from "rfuncs/functions/is_string"

export default (q, element) => {
    if (is_string(q)) {
        return [...(element || document).querySelectorAll(q)]
    }
    return q
}
