import identity from "rfuncs/functions/identity"

const next = generator => {
    const promise = generator
        .next()
        .then(({ done, value }) => ({ done, value, generator, promise }))
    return promise
}

export default async function* chain([...generators], func = identity) {
    const promises = generators.reduce(
        (set, gen) => set.add(next(gen)),
        new Set()
    )

    while (promises.size > 0) {
        const { done, value, generator, promise } = await Promise.race(promises)
        promises.delete(promise)

        if (!done) {
            promises.add(next(generator))
            yield func(value)
        }
    }
}
