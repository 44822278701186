import selector from "js/dom/selector"
import map from "rfuncs/functions/map"
import scan from "rfuncs/functions/scan"

export default (el, classname, check) => {
    return map(e => {
        scan(c => e.classList[check ? "add" : "remove"](c), classname)
        return e
    }, selector(el))
}
