<template>
    <div class="clickable h-100" @click.prevent="show_menu">
        <div v-html="content"></div>

        <portal to="page-body">
            <div
                class="offcanvas offcanvas-start w-100"
                :class="{ 'show visible': show }"
            >
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" v-html="title"></h5>
                    <button
                        class="btn-close text-reset"
                        type="button"
                        @click.prevent="hide_menu"
                    ></button>
                </div>
                <div
                    v-if="show"
                    class="offcanvas-body offcanvas-body-content pt-2"
                >
                    <div v-if="loading.public_pages" class="w-100 text-center">
                        <div class="spinner-icon muted m-3 p-3"></div>
                    </div>
                    <div class="d-flex flex-column">
                        <div
                            v-for="page in descendants"
                            :id="page_portal_id(page)"
                            :key="page.pk"
                            :style="{ order: page.kind_sequence }"
                        >
                            <megamenu
                                class="dropdown-item h5"
                                :content="page.unicode"
                                :limit="limit"
                                :sublimit="sublimit"
                                :target="page_portal_id(page)"
                                :url="page.url"
                            />
                        </div>
                    </div>

                    <div v-if="body" v-html="body" />
                    <div v-if="dom_content" v-html="dom_content" />
                </div>
            </div>
            <div
                class="offcanvas-backdrop fade"
                :class="{ 'show visible': show, invisible: !show }"
                @click.prevent="hide_menu"
            ></div>
        </portal>
    </div>
</template>

<script>
import megamenu from "js/comp/megamenu"
import portal from "js/comp/portal"
import autocomplete from "js/constants/autocomplete"
import selector from "js/dom/selector"
import state from "js/state/main"
import filter from "rfuncs/functions/filter"
import merge from "rfuncs/functions/merge"
import scan from "rfuncs/functions/scan"
import proxy from "workflow/utils/proxy"
import uuid from "workflow/utils/uuid"

export default {
    props: ["content", "title", "body", "selector", "url", "limit", "sublimit"],
    components: { portal, megamenu },
    data() {
        return {
            show: false,
            dom_content: null,
            uuid: uuid()
        }
    },
    computed: merge(
        proxy(state, ["request_id"]),
        proxy(autocomplete, [
            "pages_descendants_by_url",
            "pages_by_url",
            "loading"
        ]),
        {
            descendants() {
                if (this.pages_by_url && this.url) {
                    return filter(
                        p => p.in_navigation,
                        this.pages_descendants_by_url[this.url]
                    )
                }
            }
        }
    ),

    watch: {
        request_id() {
            this.hide_menu()
        }
    },
    methods: {
        show_menu() {
            this.dom_content = ""

            scan(
                e => (this.dom_content += e.innerHTML),
                selector(this.selector)
            )

            this.show = true
        },
        hide_menu() {
            this.show = false
        },
        page_portal_id(page) {
            return this.uuid + page.pk
        }
    }
}
</script>

<style lang="scss">
.offcanvas-body-content .dropdown-header {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid #ddd;
}
.offcanvas-body-content .megamenu-static,
.offcanvas-body-content .container {
    background: none;
    padding: 0px;
    margin: 0px;
}
</style>
