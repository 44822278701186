import composition from "rfuncs/functions/composition"
import base64safe from "workflow/utils/base64safe"
import json from "workflow/utils/json"

const encode = (v, encoding) => new TextEncoder(encoding || "ascii").encode(v)
const utf8_encoder = v => String.fromCharCode.apply(null, encode(v, "utf-8"))

const decode = (v, encoding) => new TextDecoder(encoding || "ascii").decode(v)
const utf8_decoder = v => {
    const byteArray = new Uint8Array(v.length)
    for (let i = 0; i < v.length; i++) {
        byteArray[i] = v.charCodeAt(i)
    }
    return decode(byteArray, "utf-8")
}

export const dumps = composition(json.dumps, utf8_encoder, base64safe.dumps)
export const loads = composition(base64safe.loads, utf8_decoder, json.loads)

export default { loads, dumps }
