import keys from "rfuncs/functions/keys"
import values from "rfuncs/functions/values"

// Function to transform and evaluate the string
export default (template, obj = {}) => {
    // Create a function that returns the evaluated string as a template literal
    const func = new Function(...keys(obj), `return \`${template}\`;`)

    // Pass the values of the object to the function and return the result
    return func(...values(obj))
}
