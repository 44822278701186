import download_files from "js/files/actions/download_files"
import base from "js/files/providers/base"
import persistence from "js/utils/persistence"
import all from "rfuncs/functions/all"
import any from "rfuncs/functions/any"
import contains from "rfuncs/functions/contains"
import delete_duplicates from "rfuncs/functions/delete_duplicates"
import filter from "rfuncs/functions/filter"
import group_by from "rfuncs/functions/group_by"
import identity from "rfuncs/functions/identity"
import is_array from "rfuncs/functions/is_array"
import is_string from "rfuncs/functions/is_string"
import key_drop from "rfuncs/functions/key_drop"
import key_take from "rfuncs/functions/key_take"
import keys from "rfuncs/functions/keys"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import sort from "rfuncs/functions/sort"
import to_array from "rfuncs/functions/to_array"
import { startswith } from "workflow/utils/strings"
import virtual from "workflow/vue/3/virtual"

const store = persistence.make_serializer("provider")

const validate_array_of_strings = array => {
    if (is_array(array) && all(array, is_string)) {
        return array
    }
    throw "not an array of strings"
}

const backend = virtual({
    data: () => ({
        provider: store.get() || "base",
        providers: { base: base() },
        unselected_files_names: []
    }),
    methods: merge(
        object_map(
            ([k]) => k,
            ([k, p, d]) =>
                function (v) {
                    const result = p(v)
                    const callback = this.selected_provider[k] || (() => null)
                    return callback(result) || d(result)
                },
            [
                [
                    "open_files",
                    validate_array_of_strings,
                    file_ids =>
                        download_files(key_take(backend.files, file_ids))
                ],
                [
                    "open_folders",
                    identity,
                    folders =>
                        backend.open_files(
                            filter(
                                loc => any(folders, f => startswith(loc, f)),
                                keys(backend.files)
                            )
                        )
                ],
                ["add_files", identity, download_files],
                ["remove_files", validate_array_of_strings, identity],
                ["refresh", identity, identity],
                ["cleanup", identity, identity]
            ]
        ),
        {
            select_files(files) {
                this.selected_files_names = delete_duplicates(
                    to_array(
                        this.selected_files_names,
                        map(f => f.id, to_array(files))
                    )
                )
                return files
            },
            unselect_files(files) {
                this.selected_files_names = filter(
                    k => !contains(files, k),
                    this.selected_files_names
                )
                return files
            }
        }
    ),
    watch: {
        provider: v => store.set(v)
    },
    computed: merge(
        object_map(
            k => k,
            k =>
                function () {
                    return this.selected_provider[k]
                },
            ["files", "read_only"]
        ),
        {
            selected_provider() {
                return this.providers[this.provider] || this.providers.base
            },
            selected_files() {
                return key_drop(this.files, this.unselected_files_names)
            },
            all_files_checked: {
                get: function () {
                    const s = length(this.selected_files)
                    const t = length(this.files)
                    return s == t && t > 0
                },
                set: function (v) {
                    this.unselected_files_names = v ? [] : keys(this.files)
                }
            },
            selected_files_names: {
                get: function () {
                    return sort(keys(this.selected_files))
                },
                set: function (v) {
                    this.unselected_files_names = keys(key_drop(this.files, v))
                }
            },

            files_by_id() {
                return group_by(this.files, "id")
            }
        }
    )
})

export default backend
