/**
 * register plugins
 */
import append from "js/dom/append"
import scan from "rfuncs/functions/scan"

export default (t, attrs, inner) => {
    let element = document.createElement(t)
    scan((v, k) => element.setAttribute(k, v), attrs)
    append(element, inner)
    return element
}
