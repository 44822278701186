<script>
import searchselect from "js/comp/searchselect"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import json from "workflow/utils/json"
import url_read from "workflow/utils/url_read_tracked"
import { url_query_encode } from "workflow/utils/urlutilities"

export default {
    extends: searchselect,
    props: ["adminurl", "adminfilters"],
    data() {
        return {
            selection: object_map(
                i => i.pk,
                i => this.add_url(i),
                this.value
            ),
            loading: false,
            search_results: {},
            hide_dropdown: true
        }
    },
    computed: {
        results() {
            return this.search_results
        }
    },
    watch: {
        query(q) {
            this.search(q)
        }
    },
    methods: {
        add_url(i, score) {
            return merge(i, {
                url: this.adminurl + i.pk + "/",
                score: score || 0
            })
        },
        search(q) {
            if (q.length <= 1) {
                this.search_results = {}
            } else if (!this.loading) {
                this.loading = true
                url_read(
                    this.adminurl +
                        "autocomplete/" +
                        "?" +
                        [
                            url_query_encode({ q: q }),
                            this.adminfilters || ""
                        ].join("&"),
                    {}
                ).then(r => {
                    this.loading = false
                    this.search_results = merge(
                        {
                            [q]: {
                                pk: q,
                                unicode: `<em>${q}</em>`,
                                score: 0
                            }
                        },
                        object_map(
                            v => v.pk,
                            (v, i) => this.add_url(v, i + 1),
                            json.loads(r.responseText).objects
                        )
                    )
                })
            }
        }
    }
}
</script>
