<template>
    <div class="input-autocomplete" @click.prevent>
        <div :class="{ 'input-group': !hide_dropdown }">
            <input
                ref="input"
                v-model="query"
                class="form-control"
                :placeholder="input_placeholder"
                @blur="
                    () => {
                        open = false
                        query = ''
                    }
                "
                @click="
                    () => {
                        open = true
                        show_all = false
                    }
                "
                @keydown="keydown"
            />

            <input
                v-for="(info, pk) in selection"
                ref="hidden"
                :name="name"
                type="hidden"
                :value="pk"
            />

            <select
                v-show="!hide_dropdown"
                ref="hidden"
                class="form-select flex-grow-0"
                @click.stop.prevent="
                    () => {
                        open = !open || !show_all
                        query = ''
                        show_all = true
                    }
                "
                @mousedown.stop.prevent
                @mouseup.stop.prevent
            ></select>
        </div>
        <div
            v-if="open && length(results)"
            class="autocomplete-menu no-scrollbar"
        >
            <ul class="dropdown-list dropdown-menu d-flex flex-column">
                <li
                    v-for="(info, i) in results"
                    :key="i"
                    class="dropdown-item onhover"
                    :class="{
                        selected: contains(selection, info.pk),
                        active: info.pk == active_key
                    }"
                    :style="{ order: info.score || 0 }"
                    @click.stop.prevent="toggle(info)"
                    @mousedown.stop.prevent
                    @mouseup.stop.prevent
                >
                    <a
                        class="d-flex justify-content-between align-items-center"
                    >
                        <span v-html="info.unicode" />
                        <small
                            :class="{
                                'onhover-show-visibility': info.pk != active_key
                            }"
                            >{{ info.group }}</small
                        >
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import keyboard from "js/constants/keyboard"
import fire_event from "js/dom/fire_event"
import can_hover from "js/features/can_hover"
import slugify from "js/utils/slugify"
import strip_tags from "js/utils/strip_tags"
import truncate from "js/utils/truncate"
import lunr from "lunr"
import contains from "rfuncs/functions/contains"
import first from "rfuncs/functions/first"
import is_string from "rfuncs/functions/is_string"
import key_drop from "rfuncs/functions/key_drop"
import keys from "rfuncs/functions/keys"
import last from "rfuncs/functions/last"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import scan from "rfuncs/functions/scan"
import values from "rfuncs/functions/values"

export default {
    data() {
        return {
            selection: this.options
                ? object_map(
                      v => (is_string(v) ? v : v.pk),
                      v => (is_string(v) ? this.options[v] : v),
                      this.value
                  )
                : {},
            open: false,
            show_all: true,
            query: "",
            active: -1
        }
    },
    watch: {
        selection() {
            this.$nextTick(() => fire_event(this.$refs.hidden, "change"))
        }
    },
    props: ["name", "value", "options", "disabled", "multiple"],
    computed: {
        input_placeholder() {
            const res = strip_tags(
                map(s => s.unicode, values(this.selection)).join(", ")
            )

            if (length(this.selection) > 1) {
                return `${length(this.selection)} elementi: ${res}`
            }

            return res
        },
        all_options() {
            return this.options || {}
        },
        default_results() {
            if (this.show_all) {
                return this.all_options
            }

            if (this.multiple) {
                return this.selection
            }
        },
        index() {
            if (length(this.all_options)) {
                const options = this.all_options
                const index = lunr(function () {
                    this.field("unicode", { boost: 10 })
                    this.field("slug", { boost: 7 })
                    this.field("keys", { boost: 4 })
                    this.ref("pk")
                    // slugigy is used to make sure we match stuff like la Rèunion by writing Reunion
                    scan(
                        i => this.add(merge(i, { slug: slugify(i.unicode) })),
                        options
                    )
                })
                return index
            }
            return null
        },
        results() {
            if (this.query && this.index) {
                var results = this.index.search(this.query)
                return this.filter_results(
                    object_map(
                        r => r.ref,
                        r =>
                            merge(this.all_options[r.ref], {
                                score: Math.round(10000 - r.score * 10000)
                            }),
                        results
                    )
                )
            }
            return this.filter_results(this.default_results)
        },
        active_key() {
            return keys(this.results)[this.active]
        }
    },
    methods: {
        length,
        first,
        contains,
        values,
        filter_results: a => a,
        truncate: truncate,
        pop() {
            if (length(this.selection) > 0) {
                this.selection = key_drop(
                    this.selection,
                    last(keys(this.selection))
                )
            }
        },
        toggle(info) {
            if (this.multiple) {
                if (contains(this.selection, info.pk)) {
                    this.selection = key_drop(this.selection, info.pk)
                } else {
                    this.selection = merge(this.selection, { [info.pk]: info })
                }
            } else {
                this.open = false
                this.query = ""
                this.selection = { [info.pk]: info }
            }

            if (can_hover()) this.$refs.input.focus()
        },
        keydown(e) {
            this.show_all = true

            switch (e.keyCode) {
                case keyboard.BACKSPACE:
                    if (!this.query) {
                        this.pop()
                    }
                    break
                case keyboard.ESCAPE:
                    this.open = false
                    this.query = ""
                    break
                case keyboard.ARROW_DOWN:
                    this.active = Math.min(
                        length(this.results) - 1,
                        this.active + this.open
                    )
                    this.open = true

                    e.preventDefault()
                    break
                case keyboard.ARROW_UP:
                    this.active = Math.min(
                        Math.max(-1, this.active - this.open),
                        length(this.results) - 1
                    )
                    this.open = this.active >= 0

                    this.query = this.open ? this.query : ""

                    e.preventDefault()

                    break
                case keyboard.ENTER:
                    if (this.open && this.active >= 0) {
                        this.toggle(values(this.results)[this.active])
                    }
                    e.preventDefault()
                    break
                default:
                    this.open = true

                    this.query = this.$refs.input.value

                    break
            }
        }
    }
}
</script>
