export default cookieName => {
    const name = cookieName + "="
    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim()
        if (c.indexOf(name) == 0) {
            return c.substr(name.length)
        }
    }
    return null
}
