import identity from "rfuncs/functions/identity"
import length from "rfuncs/functions/length"
import worker_number from "workerpool/worker_number"

export default (array, func = identity, N = worker_number) => {
    const batchSize = Math.floor(array.length / N)
    const remainder = array.length % N

    const batches = []
    let start = 0

    for (let i = 0; i < N; i++) {
        const end = start + batchSize + (i < remainder ? 1 : 0)

        const batch = array.slice(start, end)

        if (length(batch)) batches.push(func(batch))

        start = end
    }

    return batches
}
