<template>
    <div v-show="cart_counter > 0" class="badge bg-primary ms-2">
        {{ cart_counter }}
    </div>
</template>

<script>
import user from "js/state/user"
import proxy from "workflow/utils/proxy"

export default {
    props: [],
    computed: proxy(user, ["cart_counter"])
}
</script>
