// prettier-ignore
export default {
    "language": "it",
    "languages": [
        "en",
        "fr",
        "it"
    ],
    "libraries": [
        "actions",
        "filecheck",
        "cookie",
        "history_types",
        "urls",
        "trackers",
        "errors"
    ]
}