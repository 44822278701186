import autocomplete from "js/comp/autocomplete"
import barcode from "js/comp/barcode"
import cart_counter from "js/comp/cart_counter"
import collapsable from "js/comp/collapsable"
import double from "js/comp/double"
import double_autocomplete from "js/comp/double_autocomplete"
import megamenu from "js/comp/megamenu"
import messages from "js/comp/messages"
import offcanvas from "js/comp/offcanvas"
import publicsearch from "js/comp/publicsearch"
import rating from "js/comp/rating"
import searchselect from "js/comp/searchselect"
import sizedatalist from "js/comp/sizedatalist"
import tags from "js/comp/tags"
import uploader from "js/comp/uploader"
import username from "js/comp/username"
import autocomplete_index from "js/constants/autocomplete"
import settings_get from "js/constants/settings_get"
import init from "js/core/init"
import singletons from "js/core/singletons"
import append from "js/dom/append"
import element from "js/dom/element"
import selector from "js/dom/selector"
import can_wasm from "js/features/can_wasm"
import ensure_unique_names from "js/files/actions/ensure_unique_names"
import upload_files from "js/files/actions/upload_files"
import wait_files from "js/files/actions/wait_files"
import new_file from "js/files/make/new_file"
import new_file_from_remote_id from "js/files/make/new_file_from_remote_id"
import register_dom_update from "js/navigation/register_dom_update"
import start_request from "js/navigation/start_request"
import state from "js/state/main"
import user from "js/state/user"
import apply from "rfuncs/functions/apply"
import first from "rfuncs/functions/first"
import group_by from "rfuncs/functions/group_by"
import identity from "rfuncs/functions/identity"
import keys from "rfuncs/functions/keys"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import scan from "rfuncs/functions/scan"
import values from "rfuncs/functions/values"
import { get_imposition_dispatch_data } from "workflow/actions/imposition_plot_target"
import add_b64_to_url from "workflow/tasks/plot/utils/add_b64_to_url"
import json from "workflow/utils/json"
import { url_query_encode } from "workflow/utils/urlutilities"
import uuid from "workflow/utils/uuid"

function extract_filenames(el, sequential_id = false) {
    return ensure_unique_names(
        map(
            (f, i) =>
                new_file_from_remote_id(
                    merge({ id: sequential_id ? `${i}` : null }, f)
                ),
            json.loads(el.getAttribute("data-filenames") || "[]")
        )
    )
}

function require_staff(func, func2) {
    return el => {
        if (user.is_staff) {
            return (func || identity)(el)
        }
        return (func2 || identity)(el)
    }
}

const components = {
    double: el =>
        init(double, el, {
            size: "70%",
            options: group_by(
                map(
                    el =>
                        apply(
                            (f, s) => ({
                                value: el.value,
                                label:
                                    el.parentNode.getAttribute("label") || "",
                                group: s ? f : f,
                                name: s ? s : ""
                            }),
                            el.innerHTML.split(": ")
                        ),
                    selector("option", el)
                ),
                info => info.label,
                array =>
                    group_by(
                        array,
                        info => info.group,
                        array =>
                            object_map(
                                info => info.value,
                                info => info.name,
                                array
                            )
                    )
            )
        }),
    double_autocomplete: el => init(double_autocomplete, el),
    autocomplete: el =>
        init(autocomplete, el, {
            value:
                json.loads(el.getAttribute("data-initial") || "null") ||
                (el.value ? [{ pk: el.value, unicode: el.value }] : []),
            options: {},
            multiple: el.matches("[multiple]")
        }),

    autocomplete_select: el =>
        autocomplete_index
            .load_autocomplete(el.getAttribute("data-autocomplete"))
            .then(content => {
                const options = {}

                scan(
                    labels =>
                        scan(
                            (unicode, pk) =>
                                (options[pk] = {
                                    pk,
                                    unicode,
                                    group: labels.name
                                }),
                            labels.objects
                        ),
                    content ? content.objects : []
                )

                return init(searchselect, el, {
                    value: el.value,
                    options
                })
            }),

    searchselect: el =>
        init(searchselect, el, {
            value: map(opt => opt.value, selector("option[selected]", el)),
            multiple: el.matches("[multiple]"),
            options: object_map(
                opt => opt.value,
                opt => ({
                    pk: opt.value,
                    unicode: opt.innerHTML,
                    group: opt.parentNode.getAttribute("label") || null
                }),
                selector("option", el)
            )
        }),

    spreadsheet: el =>
        import(
            /* webpackChunkName: "spreadsheet" */ "js/comp/spreadsheet"
        ).then(m =>
            init(m.default, el, {
                errors: json.loads(el.getAttribute("data-errors")),
                schema: json.loads(el.getAttribute("data-schema")),
                input: json.loads(el.getAttribute("value") || "[{}]"),
                name: el.getAttribute("name")
            })
        ),

    barcode: el => init(barcode, el, { value: el.value, name: el.name }),
    publicsearch: el => init(publicsearch, el),
    megamenu: el =>
        init(megamenu, el, {
            limit: Number(el.getAttribute("data-limit") || 0),
            sublimit: Number(el.getAttribute("data-sublimit") || 0),
            preserve_class: true
        }),
    rating: el => init(rating, el),
    tags: el => init(tags, el),

    collapsable: el => init(collapsable, el, { collapsed: true }),
    username: el => init(username, el),
    cart_counter: el => init(cart_counter, el),
    offcanvas: el => init(offcanvas, el),
    cookielaw: el => {
        el.addEventListener("click", e => {
            e.preventDefault()
            state.cookielaw = "1"
        })
    },
    imageload: el => {
        const strip = () => {
            el.removeAttribute("loading")
        }

        if (el.complete) {
            strip()
        } else {
            el.addEventListener("load", strip)
        }
    },
    toggleclass: el => {
        const classname = el.getAttribute("data-class")
        const target = el.getAttribute("data-target")
        el.addEventListener("click", e =>
            scan(
                e =>
                    e.classList.contains(classname)
                        ? e.classList.remove(classname)
                        : e.classList.add(classname),
                selector(target)
            )
        )
    },
    report: require_staff(el =>
        import(/* webpackChunkName: "report" */ "js/comp/report").then(m =>
            init(m.default, el, {
                report: json.loads(first(selector("script", el)).innerHTML)
            })
        )
    ),
    markdown: require_staff(el =>
        import(/* webpackChunkName: "admin" */ "js/comp/markdown").then(m =>
            init(m.default, el, { placeholder: el.getAttribute("placeholder") })
        )
    ),

    filelist: el =>
        import(/* webpackChunkName: "history" */ "js/comp/files/filelist").then(
            m =>
                init(m.default, el, {
                    filenames: extract_filenames(el)
                })
        ),

    uploader: el =>
        init(uploader, el, {
            filenames: extract_filenames(el)
        }),

    pdfviewlink: el =>
        can_wasm()
            ? el.addEventListener("click", e => {
                  const change_url = el.getAttribute("data-changeurl")

                  const file_list = extract_filenames(el, true)

                  const submit_action = change_url
                      ? async (s, status) => {
                            const existing = object_map(
                                f => f.short_name,
                                f => f,
                                file_list
                            )

                            const { actions } =
                                await get_imposition_dispatch_data(
                                    s.imposition_url("on", "all"),
                                    status
                                )

                            const files = await wait_files(
                                values(
                                    map(
                                        (url, name) =>
                                            existing[name] ||
                                            new_file({ url, name }),
                                        s.filenames
                                    )
                                )
                            )
                                .then(upload_files)
                                .then(wait_files)

                            await start_request(
                                add_b64_to_url(change_url, s.data) +
                                    "&" +
                                    url_query_encode({
                                        actions: keys(actions),
                                        files: map(f => f.remote_name, files)
                                    }),
                                {
                                    csrfmiddlewaretoken: settings_get(
                                        "settings",
                                        "csrf_token"
                                    )
                                }
                            )
                        }
                      : null

                  e.preventDefault()
                  e.stopPropagation()

                  singletons.pdfmodal({
                      imposition_url:
                          el.getAttribute("data-imposition") ||
                          el.getAttribute("href").replace(/\/pdf\//g, "/json/"),
                      hide_presets: el.getAttribute("data-hide-presets"),
                      hide_sidebar: el.getAttribute("data-hide-sidebar"),
                      folder: el.getAttribute("data-folder"),
                      submit_button: el.getAttribute("data-submit-button"),
                      submit_action: submit_action,
                      file_list: file_list
                  })
              })
            : el,
    pdfformlink: el =>
        can_wasm()
            ? el.addEventListener("click", e => {
                  e.preventDefault()
                  e.stopPropagation()
                  singletons.pdfform().then(view =>
                      view.start_modal({
                          target: el.getAttribute("href"),
                          filename: el.getAttribute("download")
                      })
                  )
              })
            : el,

    sizedatalist: el => {
        const component = element("div")
        const id = uuid()

        el.setAttribute("list", id)

        append(el.parentNode, component)

        init(
            sizedatalist,
            component,
            merge(
                object_map(
                    k => k,
                    k => json.loads(el.getAttribute(`data-${k}`) || "[]"),
                    ["units", "min", "max"]
                ),
                { autocomplete: el.getAttribute("data-autocomplete"), id: id }
            )
        )
    },

    slideshow: el => {
        if (!el.getAttribute("href"))
            el.addEventListener("click", e => {
                e.preventDefault()
                e.stopPropagation()
                singletons.slideshow().then(view =>
                    view.start_modal({
                        selector: el.getAttribute("data-selector"),
                        selected:
                            el.getAttribute("data-slide-src") ||
                            el.getAttribute("src")
                    })
                )
            })
    }
}

init(messages)

register_dom_update({
    after: () => {
        scan(el => {
            const func = components[el.getAttribute("data-component")]
            if (func) func(el)
            el.removeAttribute("data-component")
        }, selector("[data-component]"))

        scan(components.searchselect, selector("select[multiple]"))
        scan(components.imageload, selector("[loading=lazy]"))
    },
    login: () => (user.is_staff ? singletons.filesink() : null)
})
