import { dismiss_dropdowns } from "js/core/bootstrap"
import closest from "js/dom/closest"
import fire_event from "js/dom/fire_event"
import on from "js/dom/on"
import selector from "js/dom/selector"
import toggle_class from "js/dom/toggle_class"
import new_files_from_datatransfer from "js/files/make/new_files_from_datatransfer"
import check_url_is_ajax from "js/navigation/check_url_is_ajax"
import register_dom_update from "js/navigation/register_dom_update"
import start_request from "js/navigation/start_request"
import state from "js/state/main"
import storage from "js/state/storage"
import user from "js/state/user"
import form_to_query_string from "js/utils/form_to_query_string"
import scroll_to from "js/utils/scroll_to"
import is_null from "rfuncs/functions/is_null"
import scan from "rfuncs/functions/scan"
import { startswith } from "workflow/utils/strings"
import url_read from "workflow/utils/url_read_tracked"
import { add_get_param, url_parse } from "workflow/utils/urlutilities"

const FORM_PREFIX = "form:not([rel~=disable-ajax-nav])"

function get_window_location() {
    return window.location.pathname + window.location.search
}

function can_do_ajax(e) {
    // check if the user is pressing left button and is not holding command key
    return e.which == 1 && !(e.metaKey && !e.ctrlKey)
}

function update_link_query_string(href, extra) {
    let url = url_parse(href)
    url.search = url_parse(get_window_location()).search
    if (extra) {
        if (url.search) {
            url.search += "&" + extra
        } else {
            url.search = extra
        }
    }
    return url.href
}

function update_link_path(href) {
    let win = url_parse(get_window_location())
    let cur = url_parse(href)
    cur.pathname = win.pathname
    cur.search = win.search
    cur.hash = win.hash

    return cur.href
}

on("click", "a", (target, event) => {
    if (
        target.matches(
            "[data-slide-modal],.dropdown-toggle,.bootstrap-dropdown,.pdfviewlink"
        )
    ) {
        if (target.matches(".pdfviewlink")) {
            dismiss_dropdowns()
        }

        event.preventDefault()
        event.stopPropagation()
        return false
    }

    if (closest(target, ".markdown") && !check_url_is_ajax(target.href)) {
        target.target = "_blank"
    }

    if (target.matches("[data-update-search]")) {
        target.href = update_link_query_string(
            target.href,
            target.getAttribute("data-update-search")
        )
    }

    if (target.matches("[data-update-path]")) {
        target.href = update_link_path(target.href)
    }

    if (target.matches(".updatenext")) {
        target.href = add_get_param(target.href, {
            next: get_window_location()
        })
    }

    state.track_event(
        target.getAttribute("data-track-event") || "link",
        target.getAttribute("data-track-type") ||
            (check_url_is_ajax(target.href) ? "internal" : "external")
    )

    if (
        target.matches(
            "a:not([rel~=disable-ajax-nav]):not([data-toggle~=dropdown]):not([target=_blank]):not([data-slide-modal])"
        )
    ) {
        var message = target.getAttribute("data-confirm")

        if ((message && confirm(message)) || !message) {
            if (target.target == "null") {
                event.preventDefault()
                url_read(target.href)
            } else if (startswith(target.getAttribute("href"), "#")) {
                event.preventDefault()

                const hash = target.getAttribute("href")

                if (hash.length > 1) {
                    scroll_to(hash, {
                        behavior: "smooth",
                        block: "center"
                    })
                }
            } else if (
                target.href &&
                can_do_ajax(event) &&
                check_url_is_ajax(target.href)
            ) {
                event.preventDefault()
                start_request(target.href, {
                    data: null,
                    scroll_control:
                        target.getAttribute("data-scroll-control") !==
                        "prevent",
                    change_location:
                        target.getAttribute("data-change-location") !==
                        "prevent"
                })
            }
        } else {
            event.preventDefault()
        }
    }
    dismiss_dropdowns()
})

on(
    "click",
    [FORM_PREFIX + " button[type=submit]", FORM_PREFIX + " input[type=submit]"],
    target => {
        if (target.name) {
            closest(target, "form").setAttribute(
                "data-form-submit",
                target.name + "=" + (target.value || "")
            )
        }

        scan(
            (a1, a2) =>
                target.getAttribute(a1)
                    ? closest(target, "form").setAttribute(
                          a2,
                          target.getAttribute(a1)
                      )
                    : null,
            {
                method: "data-form-method",
                action: "data-form-action",
                rel: "rel"
            }
        )
    }
)

on(
    "change",
    [
        "input.submit-onchange",
        "select.submit-onchange",
        "textarea.submit-onchange",
        ".submit-onchange input:not(.prevent-submit-onchange)",
        ".submit-onchange select:not(.prevent-submit-onchange)",
        ".submit-onchange textarea:not(.prevent-submit-onchange)"
    ],
    target => {
        fire_event(closest(target, "form"), "submit")
    }
)

const get_form_url = form =>
    form.getAttribute("data-action") ||
    form.getAttribute("action") ||
    get_window_location()

function form_submit(form, e, input) {
    var url = get_form_url(form)

    if (check_url_is_ajax(url)) {
        if (e) e.preventDefault()

        var method = (form.getAttribute("method") || "get").toLowerCase()

        var data = is_null(input) ? form_to_query_string(form) : input

        if (form.getAttribute("data-form-submit"))
            data = form.getAttribute("data-form-submit") + "&" + data

        toggle_class(form, "submitted", true)

        state.track_event(
            "form_submit",
            form.getAttribute("id") || "form_submit"
        )

        if (method != "post") {
            url = url.split("?")[0] + "?" + data
            data = null
        }

        start_request(url, {
            data: data,
            scroll_control:
                form.getAttribute("data-scroll-control") !== "prevent",
            change_location:
                form.getAttribute("data-change-location") !== "prevent",
            oncomplete: () => toggle_class(form, "submitted", false)
        })
    }
}

on("submit", FORM_PREFIX, (form, e) => form_submit(form, e))

on(["dragleave", "drop", "dragend"], null, () =>
    toggle_class(document.body, "state-drag", false)
)
on("dragover", null, (t, e) => {
    e.preventDefault()
    toggle_class(document.body, "state-drag", true)
})
on("drop", null, (t, e) => {
    if (user.is_staff) {
        e.preventDefault()
        storage.add_files(new_files_from_datatransfer(e.dataTransfer.files))
    }
})

window.onpopstate = () => {
    // if is one of ours
    if (document.location.href != state.last_location) {
        start_request(get_window_location(), {
            change_location: false,
            data: null
        })
    }
    state.last_location = document.location.href
}

register_dom_update({
    after: () =>
        scan(
            form =>
                start_request(
                    `${get_form_url(form)}?${window.location.search.slice(1)}`,
                    {
                        scroll_control: false,
                        change_location: false
                    }
                ),
            selector("[data-product-cached=true]")
        )
})
