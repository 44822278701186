import scan from "rfuncs/functions/scan"
import to_array from "rfuncs/functions/to_array"

export default (events, pattern, func) => {
    if (!func) throw "not a funct"

    pattern = to_array(pattern).join(",")

    scan(event => {
        if (pattern) {
            document.addEventListener(
                event,
                e => {
                    for (
                        var target = e.target;
                        target && target != this;
                        target = target.parentNode
                    ) {
                        if (target.matches && target.matches(pattern)) {
                            func(target, e)
                            break
                        }
                    }
                },
                false
            )
        } else {
            document.addEventListener(event, e => func(e.target, e), false)
        }
    }, events)
}
