import toggle_class from "js/dom/toggle_class"
import can_hover from "js/features/can_hover"
import register_dom_update from "js/navigation/register_dom_update"
import is_firefox from "js/utils/is_firefox"

register_dom_update({
    after: () => {
        toggle_class(document.body, "can-hover", can_hover())
        toggle_class(document.body, "is-firefox", is_firefox())
    }
})
