<template>
    <div class="position-fixed bottom-0 w-100 pe-none" style="z-index: 3000">
        <div class="row g-0 justify-content-center">
            <div
                class="col-12 col-lg-6 position-relative"
                @mouseleave="toggle_hover(false)"
                @mouseover="toggle_hover(true)"
            >
                <transition-group name="message">
                    <div
                        v-for="(m, i) in messages"
                        :key="m.pk"
                        class="alert clickable m-0 mb-4 shadow text-center position-absolute bottom-0 w-100 pe-auto d-flex justify-content-center align-items-center lead"
                        :class="'alert-' + (m.class || 'success')"
                        :style="{
                            transform: `scale(${message_scale[i]}, ${message_scale[i]}) translate(0, ${message_translate[i]})`
                        }"
                        @click="message_remove(m.pk)"
                    >
                        <span v-html="m.content" />
                        <a
                            v-if="m.href"
                            class="stretched-link"
                            :href="m.href"
                            target="_blank"
                        />
                    </div>
                </transition-group>
            </div>

            <div
                class="col-12 col-lg-7 position-relative progress-tracking"
                :class="{ 'progress-loading': is_loading }"
            >
                <div
                    class="d-flex shadow mb-0 py-2 px-1 bg-white position-absolute w-100 bottom-0 rounded-top border"
                >
                    <transition-group name="tracker">
                        <div
                            v-if="length(trackers) == 0"
                            class="progress w-100 mx-1"
                        />
                        <div
                            v-for="(tracker, i) in trackers"
                            :key="i"
                            class="progress text-start bg-light position-relative progress-bar-striped progress-bar-animated mx-1"
                            :style="{
                                width: `${tracker.width * 100}%`
                            }"
                        >
                            <div
                                v-for="(status, i) in tracker.status"
                                :key="i"
                                class="progress-bar"
                                :class="{
                                    'bg-danger': i == 'danger',
                                    'progress-bar-striped progress-bar-animated':
                                        i == 'loading'
                                }"
                                :style="{ width: `${status.width * 100}%` }"
                            ></div>

                            <div
                                class="position-absolute ms-2 text-white"
                                style="mix-blend-mode: difference"
                            >
                                {{ tracker.title }}

                                <template
                                    v-if="
                                        tracker.aggregated.done <
                                        tracker.aggregated.total
                                    "
                                >
                                    &hellip;
                                </template>
                                <span v-else class="text-success">&check;</span>
                            </div>
                        </div>
                    </transition-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import task_progress from "js/comp/task_progress"
import messages from "js/state/messages"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import range from "rfuncs/functions/range"
import values from "rfuncs/functions/values"
import tracking from "workflow/state/tracking"
import make_translations from "workflow/utils/make_translations"
import proxy from "workflow/utils/proxy"

const calculate_series = (l, f, s, formatter = null) => {
    if (l == 0) {
        return []
    }

    let result = new Array(l)
    result[l - 1] = s || 1

    for (let i = l - 2; i >= 0; i--) {
        result[i] = f(result[i + 1], i)
    }

    if (formatter) {
        return map(formatter, result)
    }

    return result
}

export default {
    data: () => ({ is_hover: false }),
    props: [],
    methods: {
        values,
        length,
        toggle_hover(hover) {
            this.is_hover = hover
        }
    },
    components: { task_progress },
    computed: merge(
        proxy(messages, { messages: "messages", remove: "message_remove" }),
        proxy(tracking, ["is_loading", "trackers"]),
        {
            translations: () => make_translations("actions"),

            message_length() {
                return length(this.messages)
            },
            message_scale() {
                if (this.is_hover) {
                    return calculate_series(
                        this.message_length,
                        v => v / 1.02,
                        1
                    )
                }

                return calculate_series(this.message_length, v => v / 1.05, 1)
            },
            message_translate() {
                if (this.is_hover) {
                    return map(
                        i =>
                            `calc((${(this.message_length - i - 1) * 100}% - ${(this.message_length - i - 1) * 8}px) * -1)`,
                        range(this.message_length)
                    )
                }
                return calculate_series(
                    this.message_length,
                    v => v - 8,
                    0,
                    v => `${v}px`
                )
            }
        }
    )
}
</script>

<style lang="scss" scoped>
.alert {
    transition: all 0.2s linear;
}

.message-enter-from,
.message-leave-to {
    opacity: 0 !important;
    transition: opacity 0.2s linear;
}

.progress {
    height: 15px;
    transition: all 0.2s ease-out;
}

.progress .progress-bar {
    width: 0%;
    opacity: 1;
    transition: all 1s ease-out;
}

.tracker-enter-from,
.tracker-leave-to {
    margin: 0px !important;
    opacity: 0 !important;
    width: 0% !important;
    transition: all 0.3s ease-in !important;
}

.progress-tracking {
    opacity: 0;
    transition: opacity 0.3s ease-in 0.1s;

    &.progress-loading {
        opacity: 1;
        transition: opacity 0.3s ease-out 0.4s;
    }
}

.body-pdfmodal .progress-tracking {
    margin-bottom: 77px;

    & .shadow {
        box-shadow: none !important;
    }
}
</style>
