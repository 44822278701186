/* urlsafe logic

Encode bytes using the URL- and filesystem-safe Base64 alphabet.
Argument s is a bytes-like object to encode.  The result is returned as a
bytes object.  The alphabet uses '-' instead of '+' and '_' instead of '/'.

def b64_encode(s):
    return base64.urlsafe_b64encode(s).strip(b'=')

def b64_decode(s):
    pad = b'=' * (-len(s) % 4)
    return base64.urlsafe_b64decode(s + pad)

*/
import base64 from "workflow/utils/base64"

export function dumps(data) {
    return base64
        .dumps(data)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "")
}
export function loads(data) {
    let pad = 4 - (data.length % 4)
    if (pad == 4) {
        pad = 0
    }
    return base64.loads(
        data.replace(/\-/g, "+").replace(/\//g, "_") + "=".repeat(pad)
    )
}

export default { loads, dumps }
