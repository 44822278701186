import init from "js/core/init"

const singleton = func => data => func().then(m => init(m.default, null, data))

export default {
    filesink: singleton(
        () => import(/* webpackChunkName: "admin" */ "js/comp/files/filesink")
    ),
    pdfmodal: singleton(
        () =>
            import(/* webpackChunkName: "pdfview" */ "js/comp/pdfview/pdfmodal")
    ),
    pdfform: singleton(
        () =>
            import(/* webpackChunkName: "pdfform" */ "js/comp/pdfview/pdfform")
    ),
    slideshow: singleton(
        () => import(/* webpackChunkName: "slideshow" */ "js/comp/slideshow")
    )
}
