<template>
    <input
        v-model="barcode"
        autocomplete="off"
        class="form-control"
        :name="name"
        :placeholder="placeholder"
        type="text"
    />
</template>

<script>
import barcode_regex from "constants/barcode"
import last from "rfuncs/functions/last"
import { startswith } from "workflow/utils/strings"

export function cleanup(query) {
    const cleaned = query.replaceAll(barcode_regex.divider, " ")
    const barcodes = cleaned.split(barcode_regex.divider)
    const barcode = last(barcodes)

    if (
        barcode &&
        barcode_regex.barcode.test(barcode) &&
        barcode.length == 11 &&
        barcode.substr(3) == barcode.substr(3).toLowerCase() &&
        (startswith(barcode, "44X") ||
            startswith(barcode, "43X") ||
            startswith(barcode, "41X"))
    ) {
        return cleaned + " "
    }
    return cleaned || ""
}

export default {
    data() {
        return { barcode: cleanup(this.value) }
    },
    props: ["name", "value", "placeholder"],
    methods: {
        cleanup() {
            this.barcode = cleanup(this.barcode)
        }
    },
    watch: {
        barcode() {
            this.cleanup()
        }
    }
}
</script>
