import is_null from "rfuncs/functions/is_null"
import scan from "rfuncs/functions/scan"
import values from "rfuncs/functions/values"

export function url_parse(url) {
    var parser = document.createElement("a")
    parser.href = url

    // sample usage

    //parser.protocol // => "http:"
    //parser.hostname // => "example.com"
    //parser.port     // => "3000"
    //parser.pathname // => "/pathname/"
    //parser.search   // => "?search=test"
    //parser.hash     // => "#hash"
    //parser.host     // => "example.com:3000"

    return parser
}
export function url_decode(s) {
    try {
        return decodeURIComponent(s).replace(/\r\n|\r|\n/g, "\r\n")
    } catch (e) {
        return ""
    }
}
export function url_encode(s) {
    if (is_null(s)) {
        return ""
    }
    return encodeURIComponent(s)
}
export function url_query_decode(qs) {
    var multimap = {}
    if (!qs) return multimap
    if (qs.length > 1) {
        qs.replace(/\+/g, "%20").replace(
            /([^=&]+)=([^&]*)/g,
            function (match, hfname, hfvalue) {
                multimap[url_decode(hfname)] = url_decode(hfvalue)
            }
        )
    }
    return multimap
}
export function url_query_encode(array) {
    const mapping = []

    scan(
        (values, key) =>
            scan(
                value =>
                    mapping.push(url_encode(key) + "=" + url_encode(value)),
                values
            ),
        array
    )
    return values(mapping).join("&")
}
export function add_get_param(href, extra_params) {
    var params = url_query_decode(href.split("?")[1])

    scan((value, key) => {
        if (value) {
            params[key] = value
        } else {
            delete params[key]
        }
    }, extra_params)

    var url = href.split("?")[0]
    var extra = url_query_encode(params)
    if (extra) url += "?" + extra
    extra = href.split("#")[1]
    if (extra) url += "#" + extra
    return url
}
