import settings_set from "js/constants/settings_set"
import user_get from "js/constants/user_get"
import register_dom_update from "js/navigation/register_dom_update"

function update_user_data(_, event) {
    if (event.detail && event.detail.counter) {
        settings_set("user", user_get())
    }
}
register_dom_update({
    after: update_user_data
})
