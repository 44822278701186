export default {
    admin: func =>
        import(/* webpackChunkName: "admin" */ "js/api/modules/admin").then(m =>
            func(m.default)
        ),
    sortable: func =>
        import(/* webpackChunkName: "admin" */ "js/api/modules/sortable").then(
            m => func(m.default)
        )
}
