import { dispatch } from "workerpool/run_iterable"
import hash from "workflow/utils/hash"

export default ([namespace, func, iterator], emit) =>
    dispatch({
        namespace,
        func,
        iterator,
        emit,
        task_id: hash([namespace, func, iterator]),
        cleanup: false
    })
