<template>
    <a
        ref="link"
        class="onhover text-nowrap"
        :href="url"
        tabindex="-1"
        @blur="() => (open = false)"
        @click.prevent.stop="menu_click"
        @mouseover="menu_hover"
    >
        <span v-html="content"></span>

        <portal v-if="open" class="megamenu-static" :to="target">
            <div
                class="container"
                @mousedown.stop.prevent
                @mouseup.stop.prevent
            >
                <div class="row">
                    <div
                        v-for="page in main_descendants"
                        :key="page.pk"
                        class="col col-6"
                        :class="
                            length(main_descendants) % 3 == 0 &&
                            length(main_descendants) <= 6 &&
                            length(main_descendants) % 4 != 0
                                ? 'col-lg-4'
                                : 'col-lg-3'
                        "
                        :style="{ order: page.kind_sequence }"
                    >
                        <div
                            class="megamenu-section row-space markdown-compact"
                        >
                            <a
                                class="megamenu-preview rounded"
                                data-track-event="menu_link"
                                data-track-type="menu"
                                :href="page.url"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        (selected[page.pk]
                                            ? selected[page.pk].img
                                            : page.img) +
                                        ')'
                                }"
                                :title="page.unicode"
                                @click="open = false"
                            >
                            </a>
                            <a
                                class="h6"
                                data-track-event="menu_link"
                                data-track-type="menu"
                                :href="page.url"
                                :title="page.unicode"
                                @click="open = false"
                                >{{ page.unicode }}</a
                            >
                            <p
                                v-if="length(page.descendants) == 0"
                                class="small markdown"
                                v-html="page.desc"
                            ></p>
                            <div v-else class="d-flex flex-column">
                                <a
                                    v-for="sub in truncate_links(page)"
                                    :key="sub.pk"
                                    class="megamenu-link"
                                    data-track-event="menu_link"
                                    data-track-type="menu"
                                    :href="sub.url"
                                    :style="{ order: sub.kind_sequence }"
                                    :title="sub.unicode"
                                    @click="open = false"
                                    @mouseout="
                                        () => delete_property(selected, page.pk)
                                    "
                                    @mouseover="
                                        () =>
                                            set_property(selected, page.pk, sub)
                                    "
                                    >{{ sub.unicode }}</a
                                >
                            </div>
                            <a
                                v-if="length(page.descendants) > expand_size"
                                class="megamenu-expand"
                                href="#"
                                @click.prevent="toggle_expanded(page.pk)"
                            >
                                {{
                                    is_expanded(page.pk)
                                        ? translations.actions.HIDE
                                        : translations.actions.SHOW_ALL +
                                          " (" +
                                          length(page.descendants) +
                                          ")"
                                }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </portal>
    </a>
</template>

<script>
import portal from "js/comp/portal"
import autocomplete from "js/constants/autocomplete"
import menu from "js/constants/menu"
import start_request from "js/navigation/start_request"
import state from "js/state/main"
import filter from "rfuncs/functions/filter"
import is_array from "rfuncs/functions/is_array"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import make_translations from "workflow/utils/make_translations"
import proxy from "workflow/utils/proxy"
import uuid from "workflow/utils/uuid"
import delete_property from "workflow/vue/3/delete_property"
import set_property from "workflow/vue/3/set_property"

export default {
    data() {
        return {
            uuid: uuid(),
            open: false,
            expand_size: this.sublimit || 12,
            expanded: {},
            selected: {}
        }
    },
    components: { portal },
    props: {
        target: String,
        content: String,
        url: String,
        limit: Number,
        sublimit: Number
    },
    watch: {
        open(v) {
            if (v) {
                this.$refs.link.focus()
            } else {
                this.$refs.link.blur()
            }

            menu.delegate_open_for(v, this.uuid)

            if (v) {
                state.track_event("menu_open", "menu")
            }
        }
    },
    methods: {
        length,
        set_property,
        delete_property,
        menu_hover() {
            this.main_descendants
        },
        menu_click() {
            const action = () =>
                length(this.main_descendants) > 0
                    ? (this.open = !this.open)
                    : start_request(this.url, {})

            if (is_array(this.main_descendants)) {
                action()
            } else {
                autocomplete.promise.public_pages.then(() =>
                    this.$nextTick(() => action())
                )
            }
        },

        can_open() {
            return is_array(this.main_descendants)
        },

        descendants(url) {
            if (url && this.pages_descendants_by_url) {
                return filter(
                    p => p.in_navigation,
                    this.pages_descendants_by_url[url] || []
                )
            }
            return []
        },
        is_expanded(pk) {
            return this.expanded[pk]
        },
        toggle_expanded(pk) {
            set_property(this.expanded, pk, !this.expanded[pk])

            if (this.expanded[pk]) {
                state.track_event("menu_expand", "menu")
            }
        },
        truncate_links(page) {
            if (this.is_expanded(page.pk)) {
                return page.descendants
            }

            return filter(
                (page, index) => index < this.expand_size,
                page.descendants
            )
        }
    },
    computed: merge(
        proxy(autocomplete, [
            "pages_index",
            "pages_descendants_by_url",
            "pages_by_url"
        ]),
        {
            translations: () => make_translations("actions"),
            main_descendants() {
                if (this.pages_by_url && this.pages_by_url[this.url]) {
                    let array = this.descendants(this.url)
                    if (array && this.limit) {
                        array = array.slice(0, this.limit)
                    }
                    return map(
                        o =>
                            merge(o, {
                                descendants: this.descendants(o.url)
                            }),
                        array
                    )
                }
            }
        }
    )
}
</script>
