<template>
    <span>
        {{ name || translations.actions.GUEST }}
    </span>
</template>

<script>
import user from "js/state/user"
import merge from "rfuncs/functions/merge"
import make_translations from "workflow/utils/make_translations"
import proxy from "workflow/utils/proxy"

export default {
    props: [],
    computed: merge(proxy(user, ["name"]), {
        translations: () => make_translations("actions")
    })
}
</script>
