import run_iterable_cached from "workerpool/run_iterable_cached"
import worker_number from "workerpool/worker_number"
import chain from "workflow/utils/chain"

export default async function* (url, ...args) {
    if (!url) {
        console.warn("parse_pdf received no url")
        return
    }

    const generator = run_iterable_cached(["pdf", "parse_pdf", [url]], ...args)

    const { value: info } = await generator.next()

    yield info

    if (info.reschedule) {
        const [from, to] = info.reschedule
        const size = Math.ceil((to - from) / worker_number)

        const generators = [generator]

        for (let i = from; i < to; i += size) {
            generators.push(
                run_iterable_cached(
                    ["pdf", "split_pdf", [[url, i, Math.min(i + size, to)]]],
                    ...args
                )
            )
        }

        yield* chain(generators)
    } else {
        yield* generator
    }
}
