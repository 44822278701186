import check_url_is_ajax from "js/navigation/check_url_is_ajax"
import start_request from "js/navigation/start_request"

export default url => {
    if (check_url_is_ajax(url)) {
        start_request(url, {})
    } else {
        window.location = url
    }
}
