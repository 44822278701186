<template>
    <div class="rating-widget">
        <input :name="name" type="hidden" :value="value" />
        <i
            v-for="i in range"
            :key="i"
            class="glyphicon-star glyphicon review-star"
            :class="{ 'review-star-active': i <= value }"
            @click="() => (value = i)"
        ></i>
    </div>
</template>

<script>
import range from "rfuncs/functions/range"

export default {
    props: ["name", "value"],
    computed: { range: () => range(2, 10, 2) }
}
</script>

<style lang="scss" scoped>
.review-star {
    cursor: pointer;
}
</style>
