import identity from "rfuncs/functions/identity"
import encode from "workflow/utils/encode"

const add_version = s => `${s}@6`

function _check(key = "<__test__>") {
    try {
        localStorage.setItem(key, key)
        localStorage.removeItem(key)
        return true
    } catch (e) {
        return false
    }
}

const _has_local_storage = _check()

const persistence = {
    get: k => {
        if (_has_local_storage) {
            return localStorage.getItem(add_version(k))
        }
    },
    set: (k, v) => {
        if (_has_local_storage) {
            return localStorage.setItem(add_version(k), v)
        }
    },
    make_serializer(key, dumps = identity, loads = identity) {
        return {
            get: () => loads(persistence.get(key)),
            set: value => persistence.set(key, dumps(value))
        }
    },
    make_encoder(key) {
        return persistence.make_serializer(key, encode.dumps, v =>
            v ? encode.loads(v) : null
        )
    }
}

export default persistence
