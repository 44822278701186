import FILE_DESTINATION from "constants/alias/FILE_DESTINATION"
import FILE_ID from "constants/alias/FILE_ID"
import FILE_RANGE from "constants/alias/FILE_RANGE"
import FILE_SCALE from "constants/alias/FILE_SCALE"
import group_by from "rfuncs/functions/group_by"
import identity from "rfuncs/functions/identity"
import map from "rfuncs/functions/map"
import scan from "rfuncs/functions/scan"
import to_array from "rfuncs/functions/to_array"
import intervals_to_numbers from "workflow/tasks/plot/utils/intervals_to_numbers"
import range_to_intervals from "workflow/tasks/plot/utils/range_to_intervals"

export default (filedata, destinations, processor = identity) => {
    const res = group_by(filedata, FILE_DESTINATION, files =>
        to_array(
            ...map(
                f =>
                    map(
                        page =>
                            processor({
                                page,
                                filename: f[FILE_ID],
                                scale: f[FILE_SCALE]
                            }),
                        intervals_to_numbers(range_to_intervals(f[FILE_RANGE]))
                    ),
                files
            )
        )
    )

    const final = {}

    scan(({ range }, d) => {
        final[d] = {}

        scan(
            (p, i) => {
                if (res[d] && res[d][i]) final[d][p] = processor(res[d][i])
            },
            intervals_to_numbers(range_to_intervals(range))
        )
    }, destinations)

    return final
}
