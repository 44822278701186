import toggle_class from "js/dom/toggle_class"
import register_dom_update from "js/navigation/register_dom_update"
import user from "js/state/user"

function update_body_classes() {
    // adding cookie law banner logic
    // const cookielaw_big = window.location.hash == "#/accept-cookie"
    // toggle_class(
    //     document.body,
    //     "body-cookielaw",
    //     !state.cookielaw && !cookielaw_big
    // )
    // toggle_class(
    //     document.body,
    //     "body-cookielaw-big",
    //     !state.cookielaw && cookielaw_big
    // )

    // other body classes
    toggle_class(document.body, "body-loading", false)
    toggle_class(document.body, "body-user", user.is_just_user)
    toggle_class(document.body, "body-staff", user.is_just_staff)
}

register_dom_update({
    login: update_body_classes,
    logout: update_body_classes,
    after: update_body_classes
})
