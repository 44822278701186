import wait_files_generator from "js/files/actions/wait_files_generator"
import new_file from "js/files/make/new_file"
import zip_files from "workflow/actions/zip_files"
export default (files, name) =>
    new_file({
        factory: f =>
            zip_files(
                wait_files_generator(files, f => ({
                    url: f.url,
                    name: f.meta ? `${f.meta}/${f.short_name}` : f.short_name
                }))
            ),
        name: name || "file.zip"
    })
