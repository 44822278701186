import fire_event from "js/dom/fire_event"
import filter from "rfuncs/functions/filter"
import identity from "rfuncs/functions/identity"
import items from "rfuncs/functions/items"
import keys from "rfuncs/functions/keys"
import length from "rfuncs/functions/length"
import object_map from "rfuncs/functions/object_map"
import partial from "rfuncs/functions/partial"
import values from "rfuncs/functions/values"
import * as tracker_names from "translations/enum/trackers"
import register_callback from "workerpool/register_callback"
import make_translations from "workflow/utils/make_translations"
import render_string from "workflow/utils/render_string"
import sleep from "workflow/utils/sleep"
import uuid from "workflow/utils/uuid"
import delete_property from "workflow/vue/3/delete_property"
import virtual from "workflow/vue/3/virtual"

const now = () => new Date().getTime()

const IMMEDIATE_DELETE_MS = 250

export default virtual({
    data() {
        return {
            tasks: {}
        }
    },
    methods: {
        track_promise(promise_factory, ...args) {
            const task_id = uuid()

            const promise = this.start(task_id).then(() =>
                Promise.resolve(
                    promise_factory(partial(this.emit, task_id), task_id)
                )
            )

            promise.then(() => this.finish(task_id, null, ...args))
            promise.catch(error => this.finish(task_id, error, ...args))

            return promise
        },

        async start(task_id) {
            //console.info("task started", { task_id })

            return (this.tasks[task_id] = this.tasks[task_id] || {
                error: null,
                finished: false,
                status: {},
                start_at: now()
            })
        },

        async emit(
            task_id,
            progress_type,
            progress_id,
            { done = null, total = null }
        ) {
            //console.info("task updated", {task_id,progress_type,progress_id,status})

            const t = await this.start(task_id)

            if (!t.status[progress_type]) {
                t.status[progress_type] = {}
            }
            if (!t.status[progress_type][progress_id]) {
                t.status[progress_type][progress_id] = {}
            }

            const r = t.status[progress_type][progress_id]

            r.done = done || r.done || 0
            r.total = Math.max(total || r.total || 1, r.done)
        },

        async finish(task_id, error, timeout = null) {
            //console.info("task finished", { task_id, error })

            const t = await this.start(task_id)

            t.finished = true
            t.error = error

            for (const s of values(t.status)) {
                for (const c of values(s)) {
                    c.done = c.total
                }
            }

            const diff = now() - t.start_at

            const delay = error
                ? 5000
                : diff <= IMMEDIATE_DELETE_MS
                  ? 1
                  : timeout || 2500

            sleep(delay).then(partial(this.cleanup_tracker, task_id))

            error ? fire_event(document, "tracked_error", error) : null
        },
        cleanup_tracker(task_id) {
            const to_delete = keys(
                filter((t, pk) => t.aggregated.is_finished, this.trackers)
            )

            for (const [task_id, t] of items(this.tasks)) {
                for (const pk of to_delete) {
                    delete_property(t.status, pk)
                }
                if (length(t.status) == 0) {
                    delete_property(this.tasks, task_id)
                }
            }
        }
    },
    computed: {
        translations: () => make_translations("trackers"),

        is_loading() {
            return length(this.trackers) > 0
        },

        trackers() {
            const blank = () => ({
                done: 0,
                total: 0,
                task_done: 0,
                task_total: 0,
                get is_finished() {
                    return this.task_total - this.task_done == 0
                }
            })

            const trackers = object_map(
                identity,
                id => {
                    const trans = this.translations.trackers[id]

                    return {
                        get title() {
                            return trans
                                ? render_string(trans, this.aggregated)
                                : null
                        },

                        get width() {
                            return 1 / length(trackers)
                        },

                        status: object_map(
                            identity,
                            k => ({
                                ...blank(),
                                get width() {
                                    return (
                                        this.done /
                                        trackers[id].aggregated.total
                                    )
                                }
                            }),
                            ["danger", "success", "loading"]
                        ),
                        aggregated: blank()
                    }
                },
                tracker_names
            )

            for (const [task_id, task] of items(this.tasks)) {
                const key = task.error
                    ? "danger"
                    : task.finished
                      ? "success"
                      : "loading"

                for (const [id, counters] of items(task.status)) {
                    for (const { done, total } of values(counters)) {
                        for (const r of [
                            trackers[id].status[key],
                            trackers[id].aggregated
                        ]) {
                            r.done += done
                            r.total += total

                            r.task_done += task.finished ? 1 : 0
                            r.task_total += 1
                        }
                    }
                }
            }

            for (const [id, { aggregated }] of items(trackers)) {
                if (!aggregated.task_total) {
                    delete trackers[id]
                }
            }

            return trackers
        }
    },
    mounted() {
        register_callback(
            ({ namespace, func, task_id }, ...args) =>
                this.start(task_id, ...args),
            ({ namespace, func, task_id }, ...args) =>
                this.emit(task_id, ...args),
            ({ namespace, func, task_id }, ...args) =>
                this.finish(task_id, ...args)
        )
    }
})
