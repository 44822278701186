import user_get from "js/constants/user_get"
import fire_event from "js/dom/fire_event"
import clear_ajax_cache from "js/navigation/clear_ajax_cache"
import messages from "js/state/messages"
import { get_translations } from "workflow/utils/make_translations"
import translate from "workflow/utils/render_string"
import virtual from "workflow/vue/3/virtual"

export default virtual({
    data: () => user_get(),
    mounted() {
        this.$nextTick(this.dispatch_event)
    },
    methods: {
        dispatch_event(initial = true) {
            if (this.pk) {
                fire_event(document, "user_login", { initial })

                const current = new Date().getTime() / 1000

                if (current - this.signup_time <= 5) {
                    fire_event(document, "user_signup", { initial })
                }
            } else {
                fire_event(document, "user_logout", { initial })
            }
        }
    },
    computed: {
        is_just_user: function () {
            return this.pk && !this.is_staff ? true : false
        },
        is_just_staff: function () {
            return this.pk && this.is_staff ? true : false
        }
    },
    watch: {
        pk: function (pk) {
            if (pk) {
                get_translations("actions").then(actions =>
                    messages.add(
                        translate(actions.LOGIN, {
                            user: this.email || this.name || this.pk
                        })
                    )
                )
            }

            this.dispatch_event(false)

            clear_ajax_cache()
        },
        has_discount: () => clear_ajax_cache()
    }
})
