import range from "rfuncs/functions/range"

export default (intervals, max_pages = 10000000) => {
    const result = []

    for (const [a, b] of intervals || []) {
        for (const r of range(a, Math.min(b, max_pages))) {
            result.push(r)
        }
    }
    return result
}
