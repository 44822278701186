import selector from "js/dom/selector"
import is_string from "rfuncs/functions/is_string"
import map from "rfuncs/functions/map"
import scan from "rfuncs/functions/scan"

export default (elements, content) => {
    return map(el => {
        scan(c => {
            if (is_string(c)) {
                el.innerHTML += c
            } else {
                el.appendChild(c)
            }
        }, content)
        return content
    }, selector(elements))
}
