import scan from "rfuncs/functions/scan"

export default files => {
    const names = {}

    scan(f => {
        if (names[f.name]) {
            f.uuid = `${names[f.name]}`
            names[f.name] += 1
        } else {
            names[f.name] = 1
        }
    }, files)

    return files
}
