import get_django_url from "js/utils/get_django_url"
import first from "rfuncs/functions/first"
import group_by from "rfuncs/functions/group_by"
import identity from "rfuncs/functions/identity"
import key_take from "rfuncs/functions/key_take"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import ordered_object from "rfuncs/functions/ordered_object"
import partial from "rfuncs/functions/partial"
import scan from "rfuncs/functions/scan"
import sort_by from "rfuncs/functions/sort_by"
import values from "rfuncs/functions/values"
import zip from "rfuncs/functions/zip"
import json from "workflow/utils/json"
import url_read from "workflow/utils/url_read_tracked"
import virtual from "workflow/vue/3/virtual"
const decompress = ({ keys, values }, processor = a => a) =>
    object_map(first, c => processor(ordered_object(zip(keys, c))), values)

const process_tag = tag => {
    tag.counter = 0
    return tag
}

const process_page = (tags, page) => {
    scan(t => (tags[t].counter += 1), page.tags)

    page.kind = tags[first(page.tags)]
    page.tags = key_take(tags, page.tags)

    page.kind_sequence = page.kind.sequence * 10000 + page.sequence

    return page
}

const public_autocomplete = [
    "regions",
    "public_pages",
    "switch",
    "imposition_preset",
    "standard_sizes"
]

export default virtual({
    data: () => {
        return {
            autocomplete: object_map(identity, () => null, public_autocomplete),
            loading: object_map(identity, () => null, public_autocomplete),
            promise: object_map(identity, () => null, public_autocomplete)
        }
    },
    methods: {
        load_autocomplete(name) {
            return new Promise((resolve, reject) => {
                if (this.autocomplete[name]) {
                    resolve(this.autocomplete[name])
                }

                if (!this.autocomplete[name] && !this.loading[name]) {
                    this.loading[name] = true
                    this.promise[name] = get_django_url("autocomplete", {
                        name
                    })
                        .then(url_read)
                        .then(r => {
                            this.autocomplete[name] = json.loads(r.responseText)
                            this.loading[name] = false
                            console.info("finished autoloading for " + name)

                            resolve(this.autocomplete[name])
                        })
                }
            })
        }
    },
    computed: merge(
        object_map(
            identity,
            name =>
                function () {
                    this.load_autocomplete(name)
                    return this.autocomplete[name]
                },
            public_autocomplete
        ),
        {
            tags_index: function () {
                if (this.public_pages) {
                    return decompress(
                        this.public_pages.objects.tags,
                        process_tag
                    )
                }
            },
            pages_index: function () {
                if (this.public_pages) {
                    return decompress(
                        this.public_pages.objects.pages,
                        partial(process_page, this.tags_index)
                    )
                }
            },
            pages_by_url: function () {
                if (this.pages_index) {
                    return object_map(
                        o => o.url,
                        o => o,
                        this.pages_index
                    )
                }
            },
            pages_descendants_by_url: function () {
                if (this.pages_index) {
                    return group_by(
                        values(this.pages_index),
                        p => {
                            if (p.parent) {
                                const res = this.pages_index[p.parent]
                                if (res) {
                                    return res.url
                                }
                            }
                        },
                        objs => sort_by(objs, ["kind_sequence", "unicode"])
                    )
                }
            }
        }
    )
})
