<template>
    <div v-if="page" class="d-flex">
        <button
            v-for="t in page.tags"
            :key="t.pk"
            class="btn btn-sm me-2 d-none d-sm-inline-block"
            :class="'btn-' + t.style"
            :style="{ order: t.sequence }"
            @click.prevent="() => click(t)"
        >
            {{ t.name }}
        </button>
    </div>
</template>

<script>
import autocomplete from "js/constants/autocomplete"
import menu from "js/constants/menu"
import merge from "rfuncs/functions/merge"
import proxy from "workflow/utils/proxy"

export default {
    props: ["url"],
    methods: {
        click: t => (menu.tag = t.pk)
    },
    computed: merge(proxy(autocomplete, ["pages_by_url"]), {
        page() {
            if (this.pages_by_url) {
                return this.pages_by_url[this.url]
            }
        }
    })
}
</script>
