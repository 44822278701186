import new_file from "js/files/make/new_file"
import get_media_urls from "js/files/naming/get_media_urls"
import guess_filename from "js/files/naming/guess_filename"
import make_from_filedata from "js/files/naming/make_from_filedata"
import split_filename from "js/files/naming/split_filename"
import get_content_type_for_response from "js/utils/get_content_type_for_response"
import get_filename_for_response from "js/utils/get_filename_for_response"
import identity from "rfuncs/functions/identity"
import merge from "rfuncs/functions/merge"
import { FILE_NOT_FOUND } from "translations/enum/errors"
import tracking from "workflow/state/tracking"
import create_blob from "workflow/utils/create_blob"
import hash from "workflow/utils/hash"
import url_read from "workflow/utils/url_read"

const http_to_blob = xhttp => {
    const type = this.type || get_content_type_for_response(xhttp)
    const name = this.name || get_filename_for_response(xhttp)
    const url = create_blob(new Uint8Array(xhttp.response), type)

    return { url, name, type }
}

const get_first_url = async (emit, urls, suggested_type, suggested_name) => {
    for (const current of urls) {
        console.info("trying download file", current)
        const xhttp = await url_read(
            await current,
            {
                response_type: "arraybuffer",
                with_credentials: false,
                progress: emit
            },
            null
        ).catch(identity)
        if (xhttp.status == 200) {
            const type = suggested_type || get_content_type_for_response(xhttp)
            const name = suggested_name || get_filename_for_response(xhttp)
            const url = create_blob(new Uint8Array(xhttp.response), type)

            return { url, name, type }
        }
    }

    throw FILE_NOT_FOUND
}

export default ({ remote_id, remote_name, folder, type, id }) => {
    const name = make_from_filedata(
        merge(split_filename(guess_filename(remote_name)), {
            folder
        })
    )

    return new_file({
        id: id || `remote-${hash(remote_id)}`,
        name: name,
        factory: f =>
            tracking.track_promise(emit =>
                get_first_url(emit, get_media_urls(remote_id), type, name)
            ),
        remote_id,
        type
    })
}
