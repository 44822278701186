import FILEMETA_SPLIT_CHARACTER from "constants/FILEMETA_SPLIT_CHARACTER"
import split_filename from "js/files/naming/split_filename"

export default filename => {
    const { folder, basename, ext } = split_filename(filename)

    const [name, ...meta] = basename.split(FILEMETA_SPLIT_CHARACTER)
    return {
        basename: name || "",
        meta: meta.join(FILEMETA_SPLIT_CHARACTER),
        folder,
        ext
    }
}
