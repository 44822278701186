import intercom from "js/api/intercom"
import load from "js/api/load"
import ready from "js/api/ready"
import tagmanager from "js/api/tagmanager"
import settings_extend from "js/constants/settings_extend"
import settings_set from "js/constants/settings_set"
import singletons from "js/core/singletons"
import fire_event from "js/dom/fire_event"
import ensure_unique_names from "js/files/actions/ensure_unique_names"
import new_file_from_remote_id from "js/files/make/new_file_from_remote_id"
import change_window_location from "js/navigation/change_window_location"
import clear_ajax_cache from "js/navigation/clear_ajax_cache"
import messages from "js/state/messages"
import storage from "js/state/storage"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import scan from "rfuncs/functions/scan"

/* EXPOSING WINDOW API */

const exposed_functions = {
    change_window_location,
    clear_ajax_cache,
    ready,
    load,
    intercom,
    tagmanager,
    settings_set,
    settings_done: () => fire_event(document, "site_init"),
    register_event_handler: code => settings_extend("event_handlers", code),
    add_message: message => messages.add(message),
    add_files: files =>
        storage.add_files(
            ensure_unique_names(
                map(
                    ([remote_id, remote_name, folder, type]) =>
                        new_file_from_remote_id({
                            remote_id,
                            remote_name,
                            folder,
                            type
                        }),
                    files
                )
            )
        ),
    pdfmodal: (spec, files) =>
        singletons.pdfmodal(
            merge(spec, {
                file_list: map(new_file_from_remote_id, files || [])
            })
        )
}

function push([name, ...a]) {
    return exposed_functions[name](...a)
}

scan(push, window.commands || [])

window.commands = { push }
