export function dumps(data) {
    if (typeof window === "undefined") {
        return Buffer.from(data).toString("base64")
    }
    return window.btoa(data)
}
export function loads(data) {
    if (typeof window === "undefined") {
        return Buffer.from(data, "base64").toString("utf-8")
    }

    return window.atob(data)
}

export default { loads, dumps }
