// This is a simple, *insecure* hash that's short, fast, and has no dependencies.
// For algorithmic use, where security isn't needed, it's way simpler than sha1 (and all its deps)
// or similar, and with a short, clean (base 36 alphanumeric) result.
// Loosely based on the Java version; see
// https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
import composition from "rfuncs/functions/composition"
import is_string from "rfuncs/functions/is_string"
import base64safe from "workflow/utils/base64safe"
import json from "workflow/utils/json"

const string_to_hash = str =>
    str.split("").reduce((a, b) => {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
    }, 0)

const any_to_string = obj => (is_string(obj) ? obj : json.dumps(obj))

export default composition(any_to_string, string_to_hash, base64safe.dumps)
