export default (e, sel) => {
    while (!e.matches(sel)) {
        // Increment the loop to the parent node
        e = e.parentNode
        if (!e || !e.matches) {
            return null
        }
    }
    // At this point, the while loop has stopped and `el` represents the element that has
    // the class you specified in the second parameter of the function `clazz`

    // Then return the matched element
    return e
}
