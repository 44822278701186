import BASE64 from "constants/alias/BASE64"
import filter from "rfuncs/functions/filter"
import identity from "rfuncs/functions/identity"
import scan from "rfuncs/functions/scan"
import encode from "workflow/utils/encode"

export default (url, ...data) => {
    if (!data) return url

    const current = new URL(url, window.location.href)

    current.searchParams.delete(BASE64)

    scan(
        d => current.searchParams.append(BASE64, encode.dumps(d)),
        filter(identity, data)
    )

    return current.href
}
