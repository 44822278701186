import map from "rfuncs/functions/map"
import to_array from "rfuncs/functions/to_array"
import chain from "workflow/utils/chain"

async function* wait_file(f, func, i) {
    yield await f.wait(func, i)
}

export default (files, func) =>
    chain(map((f, i) => wait_file(f, func, i), to_array(files)))
