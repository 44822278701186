import length from "rfuncs/functions/length"
import scan from "rfuncs/functions/scan"
import {
    finish_callbacks,
    start_callbacks,
    status_callbacks
} from "workerpool/register_callback"
import worker_number from "workerpool/worker_number"
import sleep from "workflow/utils/sleep"
import uuid from "workflow/utils/uuid"

let last_task_id = 0

const workers = {}
const tasks = {}

const get_results = task_id => (tasks[task_id] ? tasks[task_id].results : [])
const get_finished = task_id =>
    tasks[task_id] ? tasks[task_id].finished : false

const worker = task_id => {
    const task_n = last_task_id++
    const worker_id = task_n % worker_number

    if (!workers[worker_id]) {
        //console.log(`🤖 spawning worker ${worker_id} for ${task_id}`)
        const worker = new Worker(new URL("receiver.js", import.meta.url))
        worker.onmessage = worker_message_handler
        workers[worker_id] = worker
    }

    return workers[worker_id]
}

const worker_message_handler = ({
    data: { task_id, finished, started, progress, progress_id, result, error }
}) => {
    const t = tasks[task_id]

    if (started) {
        scan(f => f(t.info, error), start_callbacks)
    } else if (finished) {
        t.finished = true

        scan(f => f(t.info, error), finish_callbacks)

        if (error) {
            console.error("received error while running", t.info)
            throw error
        }
    } else if (progress) {
        scan(f => f(t.info, progress, progress_id, result), status_callbacks)
    } else {
        t.results.push(result)
    }
}

const send_messages = async (task_id, namespace, func, iterator) => {
    const w = worker(task_id)

    await w.postMessage({
        task_id: task_id,
        namespace: namespace,
        func: func
    })

    for await (const value of iterator) {
        await w.postMessage({ task_id, value })
    }

    await w.postMessage({ task_id, finished: true })
}

export async function* dispatch({
    task_id,
    namespace,
    func,
    iterator,
    progress,
    cleanup
}) {
    const is_main = !tasks[task_id]

    if (is_main) {
        tasks[task_id] = {
            info: { namespace, func, task_id },
            results: [],
            finished: false
        }

        send_messages(task_id, namespace, func, iterator) // DO NOT AWAIT
    }

    let current_l = 0
    let time = 1

    while (true) {
        const finished = get_finished(task_id)
        const now_l = length(get_results(task_id))

        if (now_l > current_l) {
            for (const e of get_results(task_id).slice(current_l, now_l)) {
                yield e
            }

            current_l = now_l
        }

        if (finished) {
            if (cleanup) {
                delete tasks[task_id]
            }

            return
        }

        await sleep(time)

        time = Math.min(time + 1, 50)
    }
}

export default ([namespace, func, iterator], emit) =>
    dispatch({
        namespace,
        func,
        iterator,
        emit,
        task_id: uuid(),
        cleanup: true
    })
