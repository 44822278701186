import is_string from "rfuncs/functions/is_string"
import { startswith } from "workflow/utils/strings"
import { url_parse } from "workflow/utils/urlutilities"

export default function check_url_is_ajax(parsed) {
    if (is_string(parsed)) {
        if (startswith(parsed, "#")) return false
        return check_url_is_ajax(url_parse(parsed))
    }
    if (parsed.protocol != "http:" && parsed.protocol != "https:") return false
    return (
        document.location.protocol == parsed.protocol &&
        document.location.hostname == parsed.hostname
    )
}
