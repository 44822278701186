// prettier-ignore
export const UPLOAD = "UPLOAD"
export const DOWNLOAD = "DOWNLOAD"
export const COMPRESS = "COMPRESS"
export const FETCH = "FETCH"
export const JSON_FILE = "JSON_FILE"
export const PLOT_PDF = "PLOT_PDF"
export const MERGE_PDF = "MERGE_PDF"
export const SPLIT_PDF = "SPLIT_PDF"
export const PARSE_PDF = "PARSE_PDF"
export const RASTERIZE_PDF = "RASTERIZE_PDF"