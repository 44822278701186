import uuid from "workflow/utils/uuid"

export default element => {
    let id = element.getAttribute("data-uuid")
    if (!id) {
        id = uuid()
        element.setAttribute("data-uuid", id)
    }
    return id
}
