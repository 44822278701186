import state from "js/state/main"
import merge from "rfuncs/functions/merge"
import scan from "rfuncs/functions/scan"
import manifest from "translations/manifest"
import { reactive } from "vue"

export const get_translations = (library, lang) =>
    import(
        /* webpackMode: "lazy" */ `translations/languages/${
            lang || manifest.language
        }/${library}.js`
    ).then(m => merge(state.translations[library] || {}, m.default))

export default (names, lang) => {
    const translations = reactive({})

    scan(library => {
        translations[library] = {}

        get_translations(library, lang).then(
            res => (translations[library] = res)
        )
    }, names || manifest.libraries)

    return translations
}
