import JSON_REDIRECT_CONTENT_TYPE from "constants/JSON_REDIRECT_CONTENT_TYPE"
import PARSABLE_COMMENT from "constants/PARSABLE_COMMENT"
import el from "js/dom/element"
import fire_event from "js/dom/fire_event"
import selector from "js/dom/selector"
import new_file_from_bytes from "js/files/make/new_file_from_bytes"
import change_window_location from "js/navigation/change_window_location"
import check_url_is_ajax from "js/navigation/check_url_is_ajax"
import state from "js/state/main"
import storage from "js/state/storage"
import get_content_type_for_response from "js/utils/get_content_type_for_response"
import get_filename_for_response from "js/utils/get_filename_for_response"
import get_header from "js/utils/get_header"
import scroll_to from "js/utils/scroll_to"
import first from "rfuncs/functions/first"
import is_string from "rfuncs/functions/is_string"
import scan from "rfuncs/functions/scan"
import { FETCH } from "translations/enum/trackers"
import tracking from "workflow/state/tracking"
import json from "workflow/utils/json"
import { startswith } from "workflow/utils/strings"
import { url_parse } from "workflow/utils/urlutilities"
import uuid from "workflow/utils/uuid"

function data_to_form_data(data) {
    if (is_string(data)) {
        const params = new URLSearchParams(data)
        const temp = new FormData()

        for (const [k, v] of params.entries()) {
            temp.append(k, v)
        }

        return temp
    }

    return data
}

const read = (url, data) =>
    tracking.track_promise(async emit => {
        await emit(FETCH, url, { total: 1 })

        const r = await fetch(
            url,
            data
                ? {
                      method: "post",
                      body: data_to_form_data(data)
                  }
                : null
        )

        await emit(FETCH, url, { done: 1, total: 1 })

        return r
    }, 10)

export default (
    url,
    {
        data = null,
        change_location = true,
        scroll_control = true,
        oncomplete = () => {}
    }
) => {
    if (!state.IS_SUBMITTED) {
        let input = first(selector(":focus"))

        state.IS_SUBMITTED = true
        state.LAST_FOCUS = input ? input.id : null
        state.AJAX_COUNTER += 1
        state.request_id = uuid()

        const parsed = url_parse(url)

        read(parsed.href, data).then(async r => {
            const text = await r.clone().text()

            const path = url_parse(get_header(r, "X-Real-Path") || r.url)
            const cttp = get_content_type_for_response(r)

            const is_parsable = startswith(text, PARSABLE_COMMENT)

            const is_redirect = cttp == JSON_REDIRECT_CONTENT_TYPE

            state.last_location = path.href
            state.IS_SUBMITTED = false

            if (is_redirect) {
                const url = json.loads(text).location
                return change_window_location(url)
            }

            if (!check_url_is_ajax(path)) {
                // the ajax request ended up doing an external redirect, should not happen
                window.location = path.href
                return
            }

            if (!is_parsable) {
                if (cttp == "text/html") {
                    document.open(cttp)
                    document.write(text)
                    document.close()

                    // after writing a new document all the state is fucked up
                    // it is a much cleaner solution to just re-perform the request when pressing back
                    window.onpopstate = () =>
                        (window.location = document.location.href)

                    window.history.pushState({ url: url }, url, url)

                    return
                }

                return storage.add_files([
                    new_file_from_bytes({
                        bytes: await r.arrayBuffer(),
                        name: get_filename_for_response(r),
                        type: cttp
                    })
                ])
            }

            fire_event(document, "before_json", {
                counter: state.AJAX_COUNTER
            })

            if (
                change_location &&
                get_header(r, "X-Change-Location") !== "prevent"
            ) {
                console.log("current url: " + window.location.href)

                if (window.location.href != path.href) {
                    console.log("pushing url to history: " + path.href)

                    window.history.pushState(
                        { url: path.href },
                        path.href,
                        path.href
                    )
                }

                state.location = path.href
            }

            const scripts = []

            scan(
                el => {
                    if (el.tagName == "SCRIPT") {
                        scripts.push(el.innerHTML)
                    } else {
                        const target = document.getElementById(
                            el.getAttribute("data-block-id")
                        )
                        const attr = el.getAttribute("data-block-replace")

                        if (attr) {
                            target.setAttribute(attr, el.getAttribute(attr))
                        } else if (target) {
                            target.replaceWith(el)
                        } else {
                            console.info(
                                `element with block name ${el.getAttribute(
                                    "data-block-id"
                                )} not found`
                            )
                        }
                    }
                },
                selector("[data-block-id]", el("html", {}, text))
            )

            scan(eval, scripts)

            if (
                scroll_control &&
                get_header(r, "X-Scroll-Control") !== "prevent"
            ) {
                setTimeout(
                    () => scroll_to(path.hash || parsed.hash || null),
                    10
                ) // the timeout is needed to prevent the scrolling to trigger before the content is addee
            }

            if (state.LAST_FOCUS) {
                let input = first(selector("#" + state.LAST_FOCUS))
                if (input) input.focus()
            }

            fire_event(document, "after_json", {
                counter: state.AJAX_COUNTER,
                initial: state.AJAX_COUNTER == 0
            })

            oncomplete(r)
        })
    }
}
