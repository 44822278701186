import filter from "rfuncs/functions/filter"
import first from "rfuncs/functions/first"
import identity from "rfuncs/functions/identity"
import last from "rfuncs/functions/last"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import scan from "rfuncs/functions/scan"

export default (range, max_pages = 10000000) => {
    const intervals = map(
        array => {
            const min = Math.min(...array, max_pages)
            const max = Math.max(...array)
            return [Math.max(min, 1), Math.max(Math.min(max, max_pages), 1)]
        },
        map(
            array => map(c => parseInt(c), array),
            filter(
                length,
                map(
                    c => filter(identity, c.split(/[- ]+/g)),
                    `${range || ""}`.replace(/[^0-9,-]+/g, "").split(",")
                )
            )
        )
    )
    if (length(intervals) <= 1) {
        return intervals
    }

    let [previous, ...rest] = intervals

    const merged = []
    scan(current => {
        if (last(previous) == first(current) - 1) {
            previous = [first(previous), last(current)]
        } else {
            merged.push(previous)
            previous = current
        }
    }, rest)
    merged.push(previous)

    return merged
}
