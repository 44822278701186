import selector from "js/dom/selector"
import first from "rfuncs/functions/first"
import key_take from "rfuncs/functions/key_take"
import keys from "rfuncs/functions/keys"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import ordered_object from "rfuncs/functions/ordered_object"
import range from "rfuncs/functions/range"
import scan from "rfuncs/functions/scan"
import to_array from "rfuncs/functions/to_array"
import values from "rfuncs/functions/values"
import zip from "rfuncs/functions/zip"
import partition from "workerpool/partition"
import run_iterable from "workerpool/run_iterable"
import run_iterable_cached from "workerpool/run_iterable_cached"
import merge_pdf from "workflow/actions/merge_pdf"
import split_pdf from "workflow/actions/split_pdf"
import expand_page_groups from "workflow/tasks/plot/utils/expand_page_groups"
import intervals_to_numbers from "workflow/tasks/plot/utils/intervals_to_numbers"
import range_to_intervals from "workflow/tasks/plot/utils/range_to_intervals"
import chain from "workflow/utils/chain"

const collect_file_info = async url => {
    const pages = {}
    for await (const { page, pdf } of split_pdf(url)) {
        pages[page] = pdf
    }
    return pages
}

const split_media_spec = (page_array, { total_media_pages }) => {
    const indexed_page_array = map(
        (p, i) => [i + 1, p],
        page_array || range(1, total_media_pages)
    )

    return partition(indexed_page_array, b => Object.fromEntries(b))
}

const split_imposition_spec = spec =>
    to_array(
        ...map(
            s =>
                map(
                    pages => [{ ...s, range: pages.join(",") }],
                    partition(intervals_to_numbers(range_to_intervals(s.range)))
                ),
            spec
        )
    )

const dispatch_imposition = async (
    opts,
    { name, action, url, file_data, destinations, filenames },
    destination,
    spec,
    layers
) => {
    let used_pages =
        expand_page_groups(file_data, key_take(destinations, destination))[
            destination
        ] || {}

    let used_filenames = {}

    scan(
        ({ filename }) => (used_filenames[filename] = filenames[filename]),
        used_pages
    )

    const info = await Promise.all(
        map(collect_file_info, values(used_filenames))
    )

    used_filenames = ordered_object(zip(keys(used_filenames), info))

    scan(
        ({ page, filename, scale }, k) =>
            (used_pages[k] = {
                scale,
                url: used_filenames[filename][page],
                page: 1
            }),
        used_pages
    )

    const printable = length(filenames) > 0
    const generators = map(
        s =>
            run_iterable(
                ["plot", action, [[url, used_pages, s, printable, layers]]],
                ...opts
            ),
        spec
    )

    return {
        url: await merge_pdf(chain(generators), ...opts),
        name: name,
        type: "application/pdf"
    }
}

const download = async (opts, { name }, url, type) => ({
    url,
    type,
    name
})

const download_selector = async (opts, args, selection, ...extra) =>
    download(
        opts,
        args,
        first(selector(selection)).getAttribute("href"),
        ...extra
    )

const actions = {
    plot_imposition_pdf: async (opts, info, destination, spec, layers) =>
        dispatch_imposition(
            opts,
            info,
            destination,
            split_imposition_spec(spec, info),
            layers
        ),
    plot_media_pdf: async (opts, info, destination, spec) =>
        dispatch_imposition(
            opts,
            info,
            destination,
            split_media_spec(spec, info)
        ),
    download,
    download_selector
}

const dispatch_action = (opts, data, action, ...args) =>
    actions[action](opts, data, ...args)

export const get_imposition_dispatch_data = async (imposition_url, ...args) => {
    const data = await run_iterable_cached(
        ["plot", "imposition_dispatch_data", [imposition_url]],
        ...args
    )
        .next()
        .then(({ value }) => value)

    if (!data.actions) {
        throw `URL must return actions, probably the imposition is truncated or invalid: ${imposition_url}`
    }

    return data
}

export default async (
    { imposition_url, filenames, target, prefix },
    ...opts
) => {
    const data = await get_imposition_dispatch_data(imposition_url, ...opts)
    const action = data.actions[target]

    if (!action) {
        throw `Invalid target ${target}, available targets are: ${keys(data.actions)}`
    }

    data.filenames = (await filenames) || {}
    data.name = prefix ? `${prefix}/${target}` : target
    data.action = first(action)

    return dispatch_action(opts, data, ...action)
}
