/* eslint-disable no-undef */
import on from "js/dom/on"

export default ({ after, before, login, logout, signup }) => {
    if (after) {
        on("after_json", null, after)
        on("site_init", null, after)
    }
    if (before) {
        on("before_json", null, before)
    }
    if (login) {
        on("user_login", null, login)
    }
    if (logout) {
        on("user_logout", null, logout)
    }
    if (signup) {
        on("user_signup", null, signup)
    }
}
