import get_filedata from "js/files/naming/get_filedata"
import get_media_urls from "js/files/naming/get_media_urls"
import make_from_filedata from "js/files/naming/make_from_filedata"
import split_filename from "js/files/naming/split_filename"
import identity from "rfuncs/functions/identity"
import is_string from "rfuncs/functions/is_string"
import key_take from "rfuncs/functions/key_take"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import scan from "rfuncs/functions/scan"
import { startswith } from "workflow/utils/strings"
import { url_parse } from "workflow/utils/urlutilities"
import uuid from "workflow/utils/uuid"
import virtual from "workflow/vue/3/virtual"

const methods = {
    start_loading() {
        this.loading = true
    },
    stop_loading() {
        this.loading = false
    },

    wait(func) {
        const p = this.schedule_mutation(func)
        this.start()
        return p
    },

    schedule_mutation(func) {
        let all = Promise.all(this.promises)

        if (func) {
            all = all.then(() => this.mutate_now(func))

            this.promises.push(all)

            return all
        }

        return all
    },
    mutate_now(func) {
        this.start_loading()

        const p = Promise.resolve(func(this)).then(r => {
            if (is_string(r)) {
                this.url = r
            } else {
                scan((v, k) => (this[k] = v), r)
            }
            return r
        })

        p.then(this.stop_loading)
        p.catch(this.stop_loading)

        return p
    },
    cleanup() {
        this.promises = []
        this.start = identity
    },

    clone(opts) {
        return new_file(
            merge(
                key_take(this, ["id", "url", "name", "type", "remote_id"]),
                opts || {}
            )
        )
    }
}

const computed = merge(
    {
        filedata: {
            get() {
                return get_filedata(this.name)
            },
            set(v) {
                this.name = make_from_filedata(v)
            }
        },
        short_name() {
            return `${this.basename}.${this.ext}`.trim()
        },

        remote_name() {
            if (this.remote_id && this.remote_id.indexOf("/") > -1) {
                const { folder, ext, basename } = split_filename(this.remote_id)

                if (startswith(folder, "t/")) {
                    return `t/${basename}/${this.basename}.${ext}`
                } else {
                    const [pre, c] = folder.split("/")
                    return `${pre}/${c}${basename}/${this.basename}.${ext}`
                }
            }
        }
    },
    object_map(
        n => n,
        n => ({
            get() {
                return this.filedata[n]
            },
            set(v) {
                this.filedata = merge(this.filedata, { [n]: v })
            }
        }),
        ["folder", "prefix", "basename", "meta", "ext"]
    )
)

const servers = map(url_parse, get_media_urls())

const infer_automatic_id = url => {
    if (url) {
        const parsed = url_parse(url)
        for (const server of servers) {
            if (server.hostname == parsed.hostname) {
                return parsed.pathname.substring(server.pathname.length)
            }
        }
    }
}

const new_file = ({ id, url, name, type, task, factory, remote_id }) =>
    virtual({
        data() {
            if (task) {
                throw "CANNOT PASS TASK TO FILE"
            }
            const promises = []

            const pk = id || uuid()

            const data = {
                id: pk,
                remote_id: remote_id || infer_automatic_id(url),
                name,
                url,
                type,
                promises,
                start: identity,
                pdf_informations: null,
                loading: false
            }

            promises.push(new Promise(r => (data.start = r)))

            return data
        },
        mounted() {
            if (factory) this.schedule_mutation(factory)
        },
        methods,
        computed
    })

export default new_file
