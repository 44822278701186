function iOS() {
    return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    )
}

let is_ios = iOS()

export default () => {
    return !is_ios
}
